import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

import { FaBalanceScaleLeft, FaPhoneAlt, FaRadiation, FaTimes } from 'react-icons/fa'
import { LuMonitorSpeaker } from 'react-icons/lu'
import { FaCalculator, FaClockRotateLeft, FaListCheck, FaMagnifyingGlassDollar, FaMoneyBillTransfer } from 'react-icons/fa6'
import { AiFillAppstore, AiFillExperiment, AiOutlineSwap } from 'react-icons/ai'
import { BiAbacus, BiLineChart } from 'react-icons/bi'
import { BsLayersHalf, BsSmartwatch } from 'react-icons/bs'
import { SiAppsignal } from 'react-icons/si'
import { RiBriefcase5Fill, RiBriefcase5Line, RiMessengerLine, RiPercentLine, RiSwapBoxLine } from 'react-icons/ri'
import { SiBitcoin } from 'react-icons/si'
import { IoMdBook } from 'react-icons/io'
import { MdOutlineDataThresholding, MdSecurity } from 'react-icons/md'
import { GiAmericanShield } from 'react-icons/gi'
import { HiPresentationChartLine } from 'react-icons/hi2'
import { MdAccountBalance, MdAccountBalanceWallet, MdOutlineSwapVerticalCircle, MdOutlineWarningAmber, MdStackedLineChart } from 'react-icons/md'

import Popup from '../common/popup/Popup'
import PositionTable from '../trading/PositionTable'
import TokenTransferEditor, { OPERATION_MODES, TRANSFER_MODES } from '../account/TokenTransferEditor'
import PricingContainer from '../symbol/PricingContainer'
import DefiLendingInfoContainer from '../symbol/DefiLendingInfoContainer'
import SymbolFundingRateContainer from '../symbol/SymbolFundingRateContainer'
import SymbolFundingRateHistory from '../symbol/SymbolFundingRateHistory'
import AccountAvailableBalanceTable from '../account/AccountAvailableBalanceTable'
import RiskContainer from '../trading/RiskContainer'
import { WORKSPACE_LAYOUT_DRAGGABLE_HANDLE_CLASSNAME } from './WorkspaceLayout'
import ExposureContainer from '../trading/ExposureContainer'
import TransactionContainer from '../trading/TransactionContainer'
import NotificationContainer from '../trading/NotificationContainer'
import ExposureAdjustmentTable from '../trading/ExposureAdjustmentTable'
import { isMetSearchStringCriteria } from '../../util/util'
import AccountRiskInfo from '../account/AccountRiskInfo'
import OptionPositions from '../market/OptionPositions'
import OptionImpliedVolatilityContainer from '../symbol/OptionImpliedVolatilityContainer'
import AlertManagement from '../support/AlertManagement'
import SpreadAnalyzer from '../trading/SpreadAnalyzer'
import TimerContainer from '../timer/TimerContainer'
import HighLowPrices from '../symbol/HighLowPrices'
import AggregatedOptionPositions from '../symbol/AggregatedOptionPositions'
import VaultContainer from '../blockchain/VaultContainer'
import SymbolManagement from '../symbol/SymbolManagement'
import LeverageManagement from '../symbol/LeverageManagement'
import ProfileFillStatistics from '../profile/ProfileFillStatistics'
import PortfolioAssetTransfer from '../trading/PortfolioAssetTransfer'
import SignalProfileContainer from '../profile/SignalProfileContainer'
import PriceAlertMonitor from '../symbol/PriceAlertMonitor'
import ExposureMonitor from '../trading/ExposureMonitor'
import PortfolioGuard from '../trading/PortfolioGuard'
import TWAPUpdaterContainer from '../profile/TWAPUpdaterContainer'
import BorrowRateChart from '../trading/BorrowRateChart'
// import LoanManagementContainer from '../loan/LoanManagementContainer'

const Item = ({ key='', name='', className='', icon, component }) => {
    return {
        key,
        name,
        className,
        icon,
        component
    }
}

const WORKSPACE_COMPONENTS = {
    ACCOUNT_AVAILABLE_BALANCE: Item({
        key: 'ACCOUNT_AVAILABLE_BALANCE',
        name: 'Available Balances',
        icon: <MdAccountBalanceWallet />,
        component: <AccountAvailableBalanceTable />
    }),
    ACCUMULATIVE_FUNDING_RATES: Item({
        key: 'ACCUMULATIVE_FUNDING_RATES',
        name: 'Accumulative Funding %',
        icon: <BiLineChart />,
        component: <SymbolFundingRateHistory />
    }),
    AGGREGATED_OPTION_POSITIONS: Item({
        key: 'AGGREGATED_OPTION_POSITIONS',
        name: 'Aggregated Option Positions',
        icon: <IoMdBook />,
        component: <AggregatedOptionPositions />
    }),
    ALERT_MANAGEMENT: Item({
        key: 'ALERT_MANAGEMENT',
        name: 'Alert Management',
        icon: <FaPhoneAlt />,
        component: <AlertManagement />
    }),
    BORROW_RATE_CHART: Item({
        key: 'BORROW_RATE_CHART',
        name: 'Borrow Rate History',
        icon: <HiPresentationChartLine />,
        component: <BorrowRateChart />
    }),
    BULK_TRANSFER: Item({
        key: 'BULK_TRANSFER',
        name: 'Bulk Transfer',
        icon: <RiSwapBoxLine />,
        component: <TokenTransferEditor 
            defaultOperationMode={OPERATION_MODES.BULK} 
            transferMode={TRANSFER_MODES.TRANSFER} />
    }),
    CROSS_MARGIN_RISK: Item({
        key: 'CROSS_MARGIN_RISK',
        name: 'Cross Margin Risk',
        icon: <FaRadiation />,
        component: <AccountRiskInfo />
    }),
    DEFI_LENDING: Item({
        key: 'DEFI_LENDING',
        name: 'Defi Lending',
        icon: <FaMagnifyingGlassDollar />,
        component: <DefiLendingInfoContainer />
    }),
    EXPOUSRE_AND_NAV: Item({
        key: 'EXPOUSRE_AND_NAV',
        name: 'Exposure & NAV',
        icon: <MdAccountBalance />,
        component: <ExposureContainer />
    }),
    EXPOSURE_ADJUSTMENT: Item({
        key: 'EXPOSURE_ADJUSTMENT',
        name: 'Exposure Adjustment',
        icon: <BiAbacus />,
        component: <ExposureAdjustmentTable />
    }),
    EXPOSURE_MONITOR: Item({
        key: 'EXPOSURE_MONITOR',
        name: 'Exposure Monitor',
        icon: <LuMonitorSpeaker />,
        component: <ExposureMonitor />
    }),
    FUNDING_RATES: Item({
        key: 'FUNDING_RATES',
        name: 'Funding Rates',
        icon: <RiPercentLine />,
        component: <SymbolFundingRateContainer />
    }),
    HIGH_LOW_PRICES: Item({
        key: 'HIGH_LOW_PRICES',
        name: 'High Low Prices',
        icon: <MdStackedLineChart />,
        component: <HighLowPrices />
    }),
    LEVERAGE_MANAGEMENT: Item({
        key: 'LEVERAGE_MANAGEMENT',
        name: 'Leverage Management',
        icon: <FaBalanceScaleLeft />,
        component: <LeverageManagement />
    }),
    LIQUIDATION_RISK: Item({
        key: 'LIQUIDATION_RISK',
        name: 'Liquidation Risk',
        icon: <MdOutlineWarningAmber />,
        component: <RiskContainer />
    }),
    // LOAN_MANAGEMENT: Item({
    //     key: 'LOAN_MANAGEMENT',
    //     name: 'Loan Management',
    //     icon: <AiFillMoneyCollect />,
    //     component: <LoanManagementContainer />
    // }),
    // NET_EXPOSURE_CHART: Item({
    //     key: 'NET_EXPOSURE_CHART',
    //     name: 'Net Exposure Chart',
    //     icon: <MdMultilineChart />,
    //     component: <ExposureChartContainer />
    // }),
    OPTION_POSITIONS: Item({
        key: 'OPTION_POSITIONS',
        name: 'Option Portfolio',
        icon: <RiBriefcase5Line />,
        component: <OptionPositions />
    }),
    POSITIONS: Item({
        key: 'POSITIONS',
        name: 'Positions',
        icon: <RiBriefcase5Fill />,
        component: <PositionTable />
    }),
    PORTFOLIO_ASSET_TRANSFER: Item({
        key: 'PORTFOLIO_ASSET_TRANSFER',
        name: 'Portfolio Asset Transfer',
        icon: <FaMoneyBillTransfer />,
        component: <PortfolioAssetTransfer />
    }),
    PORTFOLIO_GUARD: Item({
        key: 'PORTFOLIO_GUARD',
        name: 'Portfolio Guard',
        icon: <GiAmericanShield />,
        component: <PortfolioGuard />
    }),
    PROFILE_FILLS: Item({
        key: 'PROFILE_FILLS',
        name: 'Fills (Latest 2000)',
        icon: <MdOutlineSwapVerticalCircle />,
        component: <TransactionContainer />
    }),
    PROFILE_FILL_STATISTICS: Item({
        key: 'PROFILE_FILL_STATISTICS',
        name: 'Profile Fill Statistics',
        icon: <FaCalculator />,
        component: <ProfileFillStatistics shouldHaveProfileSelector />
    }),
    // PROFILE_PARAM_SCANNER: Item({
    //     key: 'PROFILE_PARAM_SCANNER',
    //     name: 'Profile Param Scanner',
    //     icon: <MdDocumentScanner />,
    //     component: <ProfileParamScannerContainer />
    // }),
    PRICE_ALERT_MONITOR: Item({
        key: 'PRICE_ALERT_MONITOR',
        name: 'Price Alert Monitor',
        icon: <MdOutlineDataThresholding />,
        component: <PriceAlertMonitor />
    }),
    NOTIFICATIONS: Item({
        key: 'NOTIFICATIONS',
        name: 'Notifications',
        icon: <RiMessengerLine />,
        component: <NotificationContainer shouldHideHeader />
    }),
    SIGNAL_PROFILES: Item({
        key: 'SIGNAL_PROFILES',
        name: 'Signal Profiles',
        icon: <SiAppsignal />,
        component: <SignalProfileContainer />
    }),
    SIMPLE_TRANSFER: Item({
        key: 'SIMPLE_TRANSFER',
        name: 'Simple Transfer',
        icon: <AiOutlineSwap />,
        component: <TokenTransferEditor 
            defaultOperationMode={OPERATION_MODES.SINGLE} 
            transferMode={TRANSFER_MODES.TRANSFER} />
    }),
    SPREAD_ANALYZER: Item({
        key: 'SPREAD_ANALYZER',
        name: 'Spread Analyzer',
        icon: <AiFillExperiment />,
        component: <SpreadAnalyzer />
    }),
    SYMBOL_MANAGEMENT: Item({
        key: 'SYMBOL_MANAGEMENT',
        name: 'Symbol Management',
        icon: <FaListCheck />,
        component: <SymbolManagement />
    }),
    TICKERS_INFO: Item({
        key: 'TICKERS_INFO',
        name: 'Tickers Info',
        icon: <SiBitcoin />,
        component: <PricingContainer />
    }),
    TIMER: Item({
        key: 'TIMER',
        name: 'Timers',
        icon: <BsSmartwatch />,
        component: <TimerContainer />
    }),
    TWAP_UPDATER: Item({
        key: 'TWAP_UPDATER',
        name: 'Twap Updater',
        icon: <FaClockRotateLeft />,
        component: <TWAPUpdaterContainer />
    }),
    VAULT: Item({
        key: 'VAULT',
        name: 'Vault',
        icon: <MdSecurity />,
        component: <VaultContainer />
    }),
    VOLATILITY_SURFACE: Item({
        key: 'VOLATILITY_SURFACE',
        name: 'Volatility Surface',
        icon: <BsLayersHalf />,
        component: <OptionImpliedVolatilityContainer />
    })
}

export default class WorkspaceComponent extends Component {
    constructor (props) {
        super(props)
        this.state = {
            selectorPopupCloseId: null,
            searchString: ''
        }
    }

    render () {
        const { componentId, componentKey, onChangeComponentKey, onClickRemove } = this.props
        const { selectorPopupCloseId, searchString } = this.state
        const workspaceComponentItem = WORKSPACE_COMPONENTS[componentKey] || {}
        const filteredComponents = _.isEmpty(searchString) ? Object.values(WORKSPACE_COMPONENTS) : _.filter(WORKSPACE_COMPONENTS, component => isMetSearchStringCriteria(component.name, searchString))
        const seivedComponents = _.sortBy(filteredComponents, 'name')
        return (
            <div className={`workspace-component ${componentKey}`}>
                <div className='workspace-component--header'>
                    <label className={WORKSPACE_LAYOUT_DRAGGABLE_HANDLE_CLASSNAME}>{workspaceComponentItem.name || 'New Component'}</label>
                    <Popup className='workspace-component--selector-popup'
                        on={'click'}
                        closeId={selectorPopupCloseId}
                        trigger={<button className='workspace-component--selector-popup--trigger'><AiFillAppstore /></button>}>
                        <Fragment>
                            <input className='workspace-component--selector-popup--search'
                                autoFocus
                                type={'text'}
                                placeholder={'Search Components'}
                                spellCheck={false}
                                value={searchString}
                                onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
                            <div className='workspace-component--selector-popup--list'>
                                {_.map(seivedComponents, workspaceComponentItem => {
                                    const { key, name, icon } = workspaceComponentItem
                                    return (
                                        <button className={'workspace-component--selector-popup--item'}
                                            key={key}
                                            onClick={() => {
                                                this.setState({ selectorPopupCloseId: uuidv4() })
                                                onChangeComponentKey(key)
                                            }}>
                                                {icon}
                                                <label>{name}</label>
                                            </button>
                                    )
                                })}
                            </div>
                        </Fragment>
                    </Popup>
                    <button className='workspace-component--remove-button' onClick={() => { onClickRemove() }}><FaTimes /></button>
                </div>
                <div className={`workspace-component--body ${componentKey}`}>
                    {!_.isNil(workspaceComponentItem.component) && React.cloneElement(workspaceComponentItem.component, { workspaceComponentId: componentId })}
                </div>
            </div>
        )
    }
}

WorkspaceComponent.propTypes = {
    componentId: PropTypes.string,
    componentKey: PropTypes.string,
    onChangeComponentKey: PropTypes.func.isRequired,
    onClickRemove: PropTypes.func.isRequired
}

WorkspaceComponent.defaultProps = {
    onChangeComponentKey: () => {},
    onClickRemove: () => {}
}

