import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import BigNumber from 'bignumber.js'
import dotProp from 'dot-prop-immutable'

import moment from 'moment'
import _ from 'lodash'

import Popup from '../common/popup/Popup'
import LegPopup from './LegPopup'
import SymbolPopup from '../symbol/NewSymbolPopup'
import ProfileLiquidations from './ProfileLiquidations'
import ProfileSwitches from './ProfileSwitches'

import { toNumberInputValue, toFixedNumber, toNumberWithSmartPrecision, areAllValuesNonEmpty } from '../../util/util'
import { INSTRUMENT_TYPES, getSymbolAttributeByName, getSymbolFundingRatePrecision, 
    isDynamicFundingRateSymbol, getPricePrecisionBySymbolItem } from '../../util/symbolUtil'
import { getProfileAccountNameBySymbol, getProfileTradingAccountNamesBySymbol, getProfileTradingAccountPortfolios, isProfileSymbolQuantityCapped, shouldProfileLegHaveLiquidationColumn } from '../../util/profileUtil'

import { getNotional } from '../../util/tradingUtil'
import { EXCHANGES_CAN_USE_REDUCE_ONLY, EXCHANGES_SHOULD_SHOW_FUNDING_INTERVAL } from '../../configs/tradingConfig'
import ProfileReduceOnlySwitches from './ProfileReduceOnlySwitches'

class QuoteSymbolTable extends Component {

    constructor (props) {
        super(props)
        this.refSymbolColors = ['bisque', '#dc80ae', '#9f7be8', 'darksalmon', 'gold']
    }

    shouldComponentUpdate (nextProps, nextState) {
        return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)
    }

    _getRefSymbolColorByIndex (index) {
        index = Math.abs(index)
        return this.refSymbolColors.length > index ? this.refSymbolColors[index] : 'white' 
    }

    _hasRefSymbol () {
        const { profile } = this.props
        return _.findIndex(profile.legs['1'].symbols, symbolItem => {
            const { refSymbols } = symbolItem
            return _.isArray(refSymbols) && _.findIndex(refSymbols, refSymbol => {
                return ![null, undefined, 'INVALID', ''].includes(refSymbol.name)
            }) > -1
        }) > -1
    }

    _hasSymbolWithFundingRate () {
        const { profile, symbol } = this.props
        const fundingRateSymbolNames = Object.keys(symbol.fundingRates)
        const fundingRateInHouseSymbolNames = Object.keys(symbol.fundingRatesInHouse)
        const result = _.has(profile, `legs.1.symbols`) && _.isArray(profile.legs[1].symbols)
            ? profile.legs[1].symbols.reduce((hasFundingRate, symbolItem) => { return hasFundingRate || fundingRateSymbolNames.includes(symbolItem.name) || fundingRateInHouseSymbolNames.includes(symbolItem.name)}, false)
            : false
        return result
    }

    _hasAdjustedThreshold () {
        const { profile } = this.props
        return _.some(profile.legs[1].symbols, (symbolItem) => {
            const { name, params } = symbolItem
            const { instrumentType, exchangeName } = getSymbolAttributeByName(name)
            return ((instrumentType === INSTRUMENT_TYPES.SWAP || (exchangeName === 'FTX' && instrumentType === INSTRUMENT_TYPES.SPOT))
                    && ['RATIO', 'PREMIUM'].includes(params.QUOTE_SPREAD_THRESHOLD_TYPE)
                    && (_.get(params, 'QUOTE_SPREAD_THRESHOLD_ADJUST_BY_FUNDING', []).includes(true) || _.get(params, 'QUOTE_SPREAD_THRESHOLD_ADJUST_BY_FUNDING_SWITCH') === true))
                || (_.has(symbolItem, `params.QUOTE_SPREAD_THRESHOLD_ADJUST_BASE_POS`)
                    && symbolItem.params.QUOTE_SPREAD_THRESHOLD_ADJUST_BASE_POS > 0)
                || (_.has(symbolItem, `params.LEG1_FX_PROD`)
                    && _.isString(symbolItem.params.LEG1_FX_PROD) 
                    && symbolItem.params.LEG1_FX_PROD.trim().length > 0 
                    && symbolItem.params.LEG1_FX_PROD !== 'INVALID')
        })
    }

    _hasReduceOnlyColumn () {
        const { profile } = this.props
        return _.has(profile, 'legs.1.symbols') && _.some(profile.legs['1'].symbols, profileSymbolItem => {
            const { exchangeName, instrumentType } = getSymbolAttributeByName(profileSymbolItem.name)
            return EXCHANGES_CAN_USE_REDUCE_ONLY.includes(exchangeName) && instrumentType !== INSTRUMENT_TYPES.SPOT
        })
    }

    isSymbolSwitchedOff ({ symbolName, accountName, side }) {
        const { runningState } = this.props
        return _.isArray(runningState.switchOffs)
            && _.some(runningState.switchOffs, { symbol: symbolName, account: accountName, side: side })
    }

    getSymbolSwitchOffItem ({ symbolName, accountName, side }) {
        const { runningState } = this.props
        return _.isArray(runningState.switchOffs)
            ? _.find(runningState.switchOffs, { symbol: symbolName, account: accountName, side: side })
            : null
    }

    handleKeyDownInput (e) {
        const { onKeyDownEnter } = this.props
        if (e.key === 'Enter' && e.target.value.length > 0 && !_.isNaN(Number(e.target.value))) {
            onKeyDownEnter()
        }
    }

    renderSymbol (symbolItem, index) {
        const { profile, runningState, symbol, positions, account, shouldShowSpreadDiffData, shouldShowOpenSize, onChangeProfile } = this.props
        const { instrumentType, quote, exchangeName } = getSymbolAttributeByName(symbolItem.name)
        const { strategyInfo } = runningState

        const hasRefSymbol = this._hasRefSymbol()
        const refSymbols = symbolItem.refSymbols || []
        const fxSymbol = _.get(symbolItem, 'params.LEG1_FX_PROD')
        const hasSymbolWithFundingRate = this._hasSymbolWithFundingRate()
        const hasLiquidationColumn = shouldProfileLegHaveLiquidationColumn({ profileItem: profile, legKey: '1', positionItems: positions, marginAccountBalances: account.balance.margin })
        const hasReduceOnlyColumn = this._hasReduceOnlyColumn()

        const strategyType = _.get(profile, `legs.1.strategy.type`)
        const strategySymbolInfo = _.has(strategyInfo, `symbols.${symbolItem.name}`) ? strategyInfo.symbols[symbolItem.name] : null
        const portfolios = getProfileTradingAccountPortfolios(profile)
    
        
        const symbolAccountName = getProfileAccountNameBySymbol(profile, symbolItem.name)
        const symbolTradingAccountNames = getProfileTradingAccountNamesBySymbol(profile, symbolItem.name)
        const unionTradingAccountNames = _.uniq([...symbolTradingAccountNames.BUY, ...symbolTradingAccountNames.SELL])
        const symbolPositionData = profile.started && _.has(runningState, `position.${symbolItem.name}`) && !_.isEmpty(runningState.position[symbolItem.name]) 
            ? runningState.position[symbolItem.name] 
            : {}
        const pricePrecision = _.has(symbol.items, symbolItem.name) ? getPricePrecisionBySymbolItem(symbol.items[symbolItem.name]) : 5

        const pricingItem = symbol.pricings[symbolItem.name]
        const lastPrice = pricingItem 
            ? (pricingItem.last ? pricingItem.last : pricingItem.bid && pricingItem.ask ? (pricingItem.bid + pricingItem.ask) / 2 : null)
            : null

        const netPosition = _.sumBy(Object.values(symbolPositionData), 'net') || 0
        const netPositionNotional = getNotional({
            symbolItem: symbol.items[symbolItem.name],
            quantity: netPosition,
            price: lastPrice,
            BTCUSDIndexLastPrice: _.get(symbol.pricings, 'btc_usdc_BINANCE_SPT.last')
        })

        const profilePortfolioNames = _.uniq(_.map(unionTradingAccountNames, accountName => {
            return _.has(account, `items.${accountName}.portfolio_name`)
                ? account.items[accountName].portfolio_name
                : null
        }))

        const symbolPositionNotionalSum = _.filter(positions, position => {
            const { product_name, account_name } = position
            const accountPortfolioName = _.has(account, `items.${account_name}.portfolio_name`) ? account.items[account_name].portfolio_name : null
            return product_name === symbolItem.name && accountPortfolioName && profilePortfolioNames.includes(accountPortfolioName)
        }).reduce((result, positionItem) => {
            const positionNotional = getNotional({
                symbolItem: symbol.items[symbolItem.name],
                quantity: Number(positionItem.long_position) - Number(positionItem.short_position),
                price: lastPrice,
                BTCUSDIndexLastPrice: _.get(symbol.pricings, 'btc_usdc_BINANCE_SPT.last')
            })
            return result + Number(positionNotional)
        }, 0)

        let bid, ask, theoryBuyPrice, theorySellPrice, adjustedTheoryBuyPrice, adjustedTheorySellPrice
        let isBidCrossedTheoryBuyPrice, isAskCrossedTheoryBuyPrice, 
            isBidCrossedTheorySellPrice, isAskCrossedTheorySellPrice, 
            isBidCrossedAdjustedTheoryBuyPrice, isAskCrossedAdjustedTheoryBuyPrice,
            isBidCrossedAdjustedTheorySellPrice, isAskCrossedAdjustedTheorySellPrice,
            adjustedTheoryBuyPriceToBidPriceDiff, adjustedTheorySellPriceToAskPriceDiff

        if (strategySymbolInfo) {
            if (_.isArray(strategySymbolInfo.price)) {
                bid = strategySymbolInfo.price[0]
                ask = strategySymbolInfo.price[1]
            }

            if (_.isArray(strategySymbolInfo.theory_price)) {
                theoryBuyPrice = strategySymbolInfo.theory_price[0]
                theorySellPrice = strategySymbolInfo.theory_price[1]
            }

            if (_.isArray(strategySymbolInfo.adjusted_theory_price)) {
                adjustedTheoryBuyPrice = strategySymbolInfo.adjusted_theory_price[0]
                adjustedTheorySellPrice = strategySymbolInfo.adjusted_theory_price[1]
            }

            const _bid = Number(bid)
            const _ask = Number(ask)
            
            if (_bid > 0 && _ask > 0) {
                isBidCrossedTheoryBuyPrice = _bid < Number(theoryBuyPrice)
                isAskCrossedTheoryBuyPrice = _ask < Number(theoryBuyPrice)
                isBidCrossedTheorySellPrice = _bid > Number(theorySellPrice)
                isAskCrossedTheorySellPrice = _ask > Number(theorySellPrice) 
    
                isBidCrossedAdjustedTheoryBuyPrice =_bid < Number(adjustedTheoryBuyPrice)
                isAskCrossedAdjustedTheoryBuyPrice = _ask < Number(adjustedTheoryBuyPrice)
                isBidCrossedAdjustedTheorySellPrice = _bid > Number(adjustedTheorySellPrice)
                isAskCrossedAdjustedTheorySellPrice = _ask > Number(adjustedTheorySellPrice)

                adjustedTheoryBuyPriceToBidPriceDiff = _bid - Number(adjustedTheoryBuyPrice)
                adjustedTheorySellPriceToAskPriceDiff = Number(adjustedTheorySellPrice) - _ask
            }
        }

        const renderRefSymbols = () => {
            return refSymbols.length > 0 && _.findIndex(refSymbols, refSymbol => ![null, undefined, 'INVALID', ''].includes(refSymbol.name)) > -1 ? (
                <div className='quote-symbol-table--ref-symbols'>
                    <div className='quote-symbol-table--ref-symbols--label'>{`Ref Symbol${refSymbols.length > 1 ? 's' : ''}`}</div>
                    <div className='quote-symbol-table--ref-symbols--symbol-names'>
                        {refSymbols.map((refSymbol, refSymbolIndex) => {
                            return (
                                <div className='quote-symbol-table--ref-symbols--symbol-name' key={refSymbolIndex} >
                                    {refSymbols.length > 1 && <span className='quote-symbol-table--ref-symbols--color-dot' style={{
                                        background: this._getRefSymbolColorByIndex(refSymbolIndex)
                                    }} />}
                                    {refSymbol.name}
                                </div>
                            )
                        })}
                    </div>
                </div>
            ) : null
        }
        
        const renderPricingData = (direction) => {
            const directionName = direction === 'BUY' ? 'ask' : 'bid'
            const displayPrice = direction === 'BUY' && _.isNumber(ask) ? ask.toFixed(pricePrecision)
                : direction === 'SELL' && _.isNumber(bid) ? bid.toFixed(pricePrecision)
                : 'N/A'
            const displayTheoryPrice = direction === 'BUY' && _.isNumber(theoryBuyPrice) ? theoryBuyPrice.toFixed(pricePrecision)
                : direction === 'SELL' && _.isNumber(theorySellPrice) ? theorySellPrice.toFixed(pricePrecision)
                : 'N/A'
            const displayAdjustedTheoryPrice = direction === 'BUY' && _.isNumber(adjustedTheoryBuyPrice) ? adjustedTheoryBuyPrice.toFixed(pricePrecision)
                : direction === 'SELL' && _.isNumber(adjustedTheorySellPrice) ? adjustedTheorySellPrice.toFixed(pricePrecision)
                : 'N/A'
            const shouldHighlightAdjutedTheoryPrice = (strategyType === 'MULTILEVEL' || portfolios.includes('vanda'))
                && (
                    (direction === 'BUY' && Number(adjustedTheoryBuyPriceToBidPriceDiff) < Number(adjustedTheorySellPriceToAskPriceDiff))
                    || (direction === 'SELL' && Number(adjustedTheoryBuyPriceToBidPriceDiff) > Number(adjustedTheorySellPriceToAskPriceDiff))
                )
            const displayAdjustedPriceDeviation = direction === 'BUY' && _.isNumber(adjustedTheoryBuyPriceToBidPriceDiff) ? adjustedTheoryBuyPriceToBidPriceDiff.toFixed(pricePrecision)
                    : direction === 'SELL' && _.isNumber(adjustedTheorySellPriceToAskPriceDiff) ? adjustedTheorySellPriceToAskPriceDiff.toFixed(pricePrecision)
                    : 'N/A'
            return (
                <td className={'quote-symbol-table--pricing-data ' + direction}>
                    <div className='quote-symbol-table--pricing-data--section best-bid-ask'>
                        <span className={`quote-symbol-table--pricing-direction-label ${directionName}`}>{directionName}</span>
                        <span>{displayPrice}</span>
                    </div>
                    <span className='quote-symbol-table--data-slash-symbol'>{'|'}</span>
                    <div className='quote-symbol-table--pricing-data--section theory-price'>
                        <span style={{
                            color: direction === 'BUY'  
                                ? (isAskCrossedTheoryBuyPrice ? '#fd8189' : isBidCrossedTheoryBuyPrice ? '#d3c267' : 'inherit')
                                : (isBidCrossedTheorySellPrice ? '#fd8189' : isAskCrossedTheorySellPrice ? '#d3c267' : 'inherit')
                        }}>{displayTheoryPrice}</span>
                    </div>
                    <span className='quote-symbol-table--data-slash-symbol'>{'|'}</span>
                    <div className='quote-symbol-table--pricing-data--section adjusted-theory-price'>
                        <span
                            className={shouldHighlightAdjutedTheoryPrice ? 'highlight' : null}
                            style={{
                            color: direction === 'BUY'  
                                ? (isAskCrossedAdjustedTheoryBuyPrice ? '#fd8189' : isBidCrossedAdjustedTheoryBuyPrice ? '#d3c267' : 'inherit')
                                : (isBidCrossedAdjustedTheorySellPrice ? '#fd8189' : isAskCrossedAdjustedTheorySellPrice ? '#d3c267' : 'inherit')
                        }}>{displayAdjustedTheoryPrice}</span>
                    </div>
                    {shouldShowSpreadDiffData &&
                    <>
                        <span className='quote-symbol-table--data-slash-symbol'>{'|'}</span>
                        <div className='quote-symbol-table--pricing-data--section adjusted-price-deviation'>
                            <span
                                className={shouldHighlightAdjutedTheoryPrice ? 'highlight' : null}
                                style={{ color: Number(displayAdjustedPriceDeviation) < 0 ? '#fd8189' : 'inherit' }}>
                                {displayAdjustedPriceDeviation}
                            </span>
                        </div>
                    </>}
                </td>
            )
        }

        const renderRefPricingData = (direction) => {
            return (
                <td className={'quote-symbol-table--ref-pricing-data ' + direction}>
                    {refSymbols.map((refSymbol, refSymbolIndex) => {
                        const strategyRefSymbolInfo = _.has(strategySymbolInfo, `ref_prods.${refSymbol.name}`) ? strategySymbolInfo.ref_prods[refSymbol.name] : null
                        const refSymbolAttribute = getSymbolAttributeByName(refSymbol.name)
                        let refPriceValue, refTheoryPriceValue, precision
                        if (_.has(symbol.items, `${refSymbol.name}.price_tick`)) {
                            const priceTick = symbol.items[refSymbol.name].price_tick
                            precision = priceTick.includes('.') ? priceTick.split('.')[1].length : 5
                        }
                        if (strategyRefSymbolInfo) {
                            refPriceValue = refSymbolAttribute.originalType === 'INDEX' ? strategyRefSymbolInfo.last 
                                : _.isArray(strategyRefSymbolInfo.price) && strategyRefSymbolInfo.price.length === 2 ? strategyRefSymbolInfo.price[direction === 'BUY' ? 1 : 0] 
                                : null
                            refTheoryPriceValue = _.isArray(strategyRefSymbolInfo.ref_theory_price) && strategyRefSymbolInfo.ref_theory_price.length === 2 ? strategyRefSymbolInfo.ref_theory_price[direction === 'BUY' ? 0 : 1] : null
                        }
                        return (
                            <div className='quote-symbol-table--ref-pricing-data--item' 
                                key={refSymbolIndex} 
                                title={refSymbol.name}>
                                {refSymbols.length > 1 && <span className='quote-symbol-table--ref-pricing-data--color-dot' style={{
                                    background: this._getRefSymbolColorByIndex(refSymbolIndex)
                                }} />}
                                <div className='quote-symbol-table--ref-pricing-data--section ref-pricing'>
                                    <span>{!_.isNil(refPriceValue) ? toFixedNumber(refPriceValue, precision) : 'N/A'}</span>
                                </div>
                                <span className='quote-symbol-table--data-slash-symbol'>{'|'}</span>
                                <div className='quote-symbol-table--ref-pricing-data--section ref-theory-price'>
                                    <span>{!_.isNil(refTheoryPriceValue) ? toFixedNumber(refTheoryPriceValue, precision) : 'N/A'}</span>
                                </div>
                            </div>
                        )
                    })}
                </td>
            )
        } 

        const renderSpreadData = (direction) => {
            const parameterIndex = direction === 'BUY' ? 0 : 1
            const hasAdjustedThreshold = this._hasAdjustedThreshold()
            const isAjustedThresholdApplied = ((instrumentType === INSTRUMENT_TYPES.SWAP || (exchangeName === 'FTX' && instrumentType === INSTRUMENT_TYPES.SPOT))
                    && ['RATIO', 'PREMIUM'].includes(symbolItem.params.QUOTE_SPREAD_THRESHOLD_TYPE)
                    && (_.get(symbolItem, `params.QUOTE_SPREAD_THRESHOLD_ADJUST_BY_FUNDING.${parameterIndex}`) === true || _.get(symbolItem, `params.QUOTE_SPREAD_THRESHOLD_ADJUST_BY_FUNDING_SWITCH`) === true))
                || (_.has(symbolItem, `params.QUOTE_SPREAD_THRESHOLD_ADJUST_BASE_POS`)
                    && symbolItem.params.QUOTE_SPREAD_THRESHOLD_ADJUST_BASE_POS > 0
                )
                || (_.has(symbolItem, `params.LEG1_FX_PROD`)
                    && _.isString(symbolItem.params.LEG1_FX_PROD)
                    && symbolItem.params.LEG1_FX_PROD.trim().length > 0 
                    && symbolItem.params.LEG1_FX_PROD !== 'INVALID')
            const spreadValue = strategySymbolInfo && _.isArray(strategySymbolInfo.spread) && strategySymbolInfo.spread.length === 2 ? strategySymbolInfo.spread[parameterIndex] : null
            const spreadThreshold = _.isArray(symbolItem.params.QUOTE_SPREAD_THRESHOLD) ? symbolItem.params.QUOTE_SPREAD_THRESHOLD[parameterIndex] : null
            const spreadThresholdBound = _.isArray(symbolItem.params.QUOTE_SPREAD_THRESHOLD_BOUND) ? symbolItem.params.QUOTE_SPREAD_THRESHOLD_BOUND[parameterIndex] : null
            const adjustedSpreadThreshold = strategySymbolInfo && isAjustedThresholdApplied && _.isArray(strategySymbolInfo.adjusted_threshold) && strategySymbolInfo.adjusted_threshold.length === 2 ? strategySymbolInfo.adjusted_threshold[parameterIndex] : null
            const isThresholdCrossed = direction === 'BUY' 
                ? (isAjustedThresholdApplied ? spreadValue < adjustedSpreadThreshold : spreadValue < spreadThreshold)
                : (isAjustedThresholdApplied ? spreadValue > adjustedSpreadThreshold : spreadValue > spreadThreshold) 
            const isThresholdCrossedBoundValue = !_.isNil(spreadThreshold) && !_.isNil(spreadThresholdBound) ? (direction === 'BUY' ? (spreadThreshold > spreadThresholdBound) : (spreadThreshold < spreadThresholdBound)) : false
            
            let _spreadDiff, _adjustedThresholdDiff
            if (shouldShowSpreadDiffData && areAllValuesNonEmpty([spreadValue, adjustedSpreadThreshold])) {
                if (areAllValuesNonEmpty([spreadValue, adjustedSpreadThreshold])) {
                    if (direction === 'BUY') {
                        _spreadDiff = Number(spreadValue) - Number(adjustedSpreadThreshold)
                    } else if (direction === 'SELL') {
                        _spreadDiff = Number(adjustedSpreadThreshold) - Number(spreadValue)
                    }
                }
                if (areAllValuesNonEmpty([adjustedSpreadThreshold, spreadThreshold])) {
                    _adjustedThresholdDiff = Number(adjustedSpreadThreshold) - Number(spreadThreshold)
                }
            }
            
            return (
                <td className={'quote-symbol-table--spread-data ' + direction}>
                    {shouldShowSpreadDiffData &&
                    <>
                        <span>{!_.isNil(_spreadDiff) ? toFixedNumber(_spreadDiff, 5) : 'N/A'}</span>
                        <span className='quote-symbol-table--data-slash-symbol'>{'|'}</span>
                    </>}
                    <span style={{
                        color: isThresholdCrossed && !_.isNull(spreadValue) ? '#d3c267' : 'inherit'
                    }}>{!_.isNil(spreadValue) ? toFixedNumber(spreadValue, 5) : 'N/A'}</span>
                    <span className='quote-symbol-table--data-slash-symbol'>{'|'}</span>
                    {hasAdjustedThreshold &&
                    <Fragment>
                        <span>{!_.isNil(adjustedSpreadThreshold) ? toFixedNumber(adjustedSpreadThreshold, 5) : 'N/A'}</span>
                        <span className='quote-symbol-table--data-slash-symbol'>{'|'}</span>
                    </Fragment>}        
                    <input 
                        className={'quote-symbol-table--spread-data--input' + (isThresholdCrossedBoundValue ? ' warning' : '')}
                        type={'number'}
                        title={'Spread Threshold' + (!_.isNil(spreadThresholdBound) ? `, Bound = ${spreadThresholdBound}` : '')} 
                        value={!_.isNull(spreadThreshold) ? spreadThreshold : ''} 
                        onKeyDown={(e) => { this.handleKeyDownInput(e) }}
                        onBlur={(e) => { 
                            if (e.target.value === '' || _.isNaN(Number(e.target.value))) {
                                const quoteSpreadThreshold = _.cloneDeep(symbolItem.params.QUOTE_SPREAD_THRESHOLD) || [0, 0]
                                quoteSpreadThreshold[parameterIndex] = 0
                                const newProfile = dotProp.set(profile, `legs.1.symbols.${index}.params.QUOTE_SPREAD_THRESHOLD`, quoteSpreadThreshold)
                                onChangeProfile(newProfile)
                            }
                        }}
                        onWheel={(e) => { e.target.blur() }}
                        onChange={(e) => {
                            const quoteSpreadThreshold = _.cloneDeep(symbolItem.params.QUOTE_SPREAD_THRESHOLD) || [0, 0]
                            quoteSpreadThreshold[parameterIndex] = toNumberInputValue(e.target.value)
                            const newProfile = dotProp.set(profile, `legs.1.symbols.${index}.params.QUOTE_SPREAD_THRESHOLD`, quoteSpreadThreshold)
                            onChangeProfile(newProfile)
                        }} />
                    {shouldShowSpreadDiffData &&
                    <>
                        <span className='quote-symbol-table--data-slash-symbol'>{'|'}</span>
                        <span>{!_.isNil(_adjustedThresholdDiff) ? toFixedNumber(_adjustedThresholdDiff, 5) : 'N/A'}</span>
                    </>}
                </td>
            )
        }

        const renderAdjustedSpreadThresholdDelta = () => {
            const _adjustedThresholds = _.get(strategySymbolInfo, 'adjusted_threshold')
            let _delta
            if (_.isArray(_adjustedThresholds) && _.size(_adjustedThresholds) === 2) {
                _delta = Number(_adjustedThresholds[1]) - Number(_adjustedThresholds[0])
            }
            return (
                <td rowSpan={2} className={`quote-symbol-table--adjusted-spread-threshold-delta`}>
                    <span>{!_.isNil(_delta) ? toFixedNumber(_delta, 5) : 'N/A'}</span>
                </td>
            )
        }

        const renderRefSpreadData = (direction) => {
            const parameterIndex = direction === 'BUY' ? 0 : 1
            return (
                <td className={'quote-symbol-table--ref-spread-data ' + direction}>
                    {refSymbols.map((refSymbol, refSymbolIndex) => {
                        const strategyRefSymbolInfo = _.has(strategySymbolInfo, `ref_prods.${refSymbol.name}`) ? strategySymbolInfo.ref_prods[refSymbol.name] : null
                        const refSpread = strategyRefSymbolInfo && _.isArray(strategyRefSymbolInfo.ref_spread) ? strategyRefSymbolInfo.ref_spread[parameterIndex] : null
                        const refSpreadThreshold = _.isArray(refSymbol.params.QUOTE_REF_SPREAD_THRESHOLD) ? refSymbol.params.QUOTE_REF_SPREAD_THRESHOLD[parameterIndex] : null
                        const isRefSpreadCrossed = !_.isNil(refSpread) && !_.isNil(refSpreadThreshold)
                            ? (direction === 'BUY' ? refSpread < refSpreadThreshold : refSpread > refSpreadThreshold)
                            : false
                        return (
                            <div className='quote-symbol-table--ref-spread-data--item' 
                                key={refSymbolIndex}
                                title={refSymbol.name}>
                                {refSymbols.length > 1 && <span className='quote-symbol-table--ref-spread-data--color-dot' style={{
                                    background: this._getRefSymbolColorByIndex(refSymbolIndex)
                                }} />}
                                <span style={{ color: isRefSpreadCrossed ? '#d3c267' : 'inherit' }}>{!_.isNil(refSpread) ? toFixedNumber(refSpread, 5) : 'N/A'}</span>
                                <span className='quote-symbol-table--data-slash-symbol'>{'|'}</span>
                                <input 
                                    type={'number'}
                                    title={'Ref Price Threshold'} 
                                    value={!_.isNull(refSpreadThreshold) ? refSpreadThreshold : ''} 
                                    onKeyDown={(e) => { this.handleKeyDownInput(e) }} 
                                    onBlur={(e) => {
                                        if (e.target.value === '' || _.isNaN(Number(e.target.value))) {
                                            const newQuoteRefSpreadThreshold = _.cloneDeep(refSymbol.params.QUOTE_REF_SPREAD_THRESHOLD) || [0, 0]
                                            newQuoteRefSpreadThreshold[parameterIndex] = 0
                                            const newProfile = dotProp.set(profile, `legs.1.symbols.${index}.refSymbols.${refSymbolIndex}.params.QUOTE_REF_SPREAD_THRESHOLD`, newQuoteRefSpreadThreshold)
                                            onChangeProfile(newProfile)
                                        }      
                                    }} 
                                    onWheel={(e) => { e.target.blur() }}
                                    onChange={(e) => {
                                        const newQuoteRefSpreadThreshold = _.cloneDeep(refSymbol.params.QUOTE_REF_SPREAD_THRESHOLD) || [0, 0]
                                        newQuoteRefSpreadThreshold[parameterIndex] = toNumberInputValue(e.target.value)
                                        const newProfile = dotProp.set(profile, `legs.1.symbols.${index}.refSymbols.${refSymbolIndex}.params.QUOTE_REF_SPREAD_THRESHOLD`, newQuoteRefSpreadThreshold)
                                        onChangeProfile(newProfile)
                                    }} />
                            </div>
                        )
                    })}
                </td>
            )
        }

        const renderPositionData = (direction='BUY') => {
            const positionData = symbolPositionData[symbolAccountName[direction]] || {}
            const parameterIndex = direction === 'BUY' ? 0 : 1
            const longPosition = positionData && !_.isNull(positionData.long) ? positionData.long : null
            const shortPosition = positionData && !_.isNull(positionData.short) ? positionData.short : null
            const virtualLongPosition = !_.isNull(longPosition) ? (longPosition + (positionData.open_long || 0)) : null
            const virtualShortPosition = !_.isNull(shortPosition) ? (shortPosition + (positionData.open_short || 0)) : null
            
            const fixedVirtualLongPosition = Number(toFixedNumber(virtualLongPosition, 4))
            const fixedVirtualShortPosition = Number(toFixedNumber(virtualShortPosition, 4))
            const maxSidePositionValue = _.isArray(symbolItem.params.QUOTE_MAX_SIDE_POS) ? symbolItem.params.QUOTE_MAX_SIDE_POS[parameterIndex] : null

            const virtualLongPositionNotional = _.isFinite(fixedVirtualLongPosition)
                ? getNotional({
                    symbolItem: symbol.items[symbolItem.name],
                    quantity: fixedVirtualLongPosition,
                    price: lastPrice,
                    BTCUSDIndexLastPrice: _.get(symbol.pricings, 'btc_usdc_BINANCE_SPT.last')
                })
                : null
            const virtualShortPositionNotional = _.isFinite(fixedVirtualShortPosition)
                ? getNotional({
                    symbolItem: symbol.items[symbolItem.name],
                    quantity: fixedVirtualShortPosition,
                    price: lastPrice,
                    BTCUSDIndexLastPrice: _.get(symbol.pricings, 'btc_usdc_BINANCE_SPT.last')
                })
                : null

            const maxSidePosNotional = _.has(symbolItem, 'params.QUOTE_TRADE_IN_NOTIONAL_VALUE') && symbolItem.params.QUOTE_TRADE_IN_NOTIONAL_VALUE === true
                ? maxSidePositionValue 
                : getNotional({
                    symbolItem: symbol.items[symbolItem.name],
                    quantity: maxSidePositionValue,
                    price: lastPrice,
                    BTCUSDIndexLastPrice: _.get(symbol.pricings, 'btc_usdc_BINANCE_SPT.last')
                })

            const isSymbolQuantityCapped = isProfileSymbolQuantityCapped({
                profileSymbolItem: symbolItem,
                profilePositionData: runningState.position,
                symbolItems: symbol.items,
                symbolPricings: symbol.pricings
            })[direction]

            const hasBuySideNotional = direction === 'BUY' && Number(virtualLongPositionNotional) !== 0
            const hasSellSideNotional = direction === 'SELL' && Number(virtualShortPositionNotional) !== 0
            return (
                <td className={'quote-symbol-table--position-data ' + direction}>
                    {(profile.started || profile.isStarting) 
                    ? <div className={'quote-symbol-table--position-data--vpos' + ((hasBuySideNotional || hasSellSideNotional) ? ' has-notional' : '')}>
                        {direction === 'BUY' ? (_.isFinite(fixedVirtualLongPosition) ? fixedVirtualLongPosition : '0') 
                        : (_.isFinite(fixedVirtualShortPosition) ? fixedVirtualShortPosition : '0')}
                        {hasBuySideNotional && 
                        <div className='quote-symbol-table--position-data--vpos-notional'>
                            {`${toNumberWithSmartPrecision({ number: virtualLongPositionNotional, shouldReturnLocalString: true, defaultPrecision: quote === 'BTC' ? 2 : 0 })} ${quote}`}
                        </div>}
                        {hasSellSideNotional && 
                        <div className='quote-symbol-table--position-data--vpos-notional'>
                            {`${toNumberWithSmartPrecision({ number: virtualShortPositionNotional, shouldReturnLocalString: true, defaultPrecision: quote === 'BTC' ? 2 : 0 })} ${quote}`}
                        </div>}
                    </div>
                    : <input
                        type={'number'} 
                        title={'Initial Position'}
                        value={symbolItem.params[direction === 'BUY' ? 'LEG1_PROD_INITIAL_LONG_POS' : 'LEG1_PROD_INITIAL_SHORT_POS']} 
                        onKeyDown={(e) => { this.handleKeyDownInput(e) }} 
                        onBlur={(e) => {
                            if (e.target.value === '' || _.isNaN(Number(e.target.value))) {
                                const newProfile = dotProp.set(profile, `legs.1.symbols.${index}.params.${direction === 'BUY' ? 'LEG1_PROD_INITIAL_LONG_POS' : 'LEG1_PROD_INITIAL_SHORT_POS'}`, 0)
                                onChangeProfile(newProfile)
                            }
                        }}
                        onWheel={(e) => { e.target.blur() }}
                        onChange={(e) => {
                            const newProfile = dotProp.set(profile, `legs.1.symbols.${index}.params.${direction === 'BUY' ? 'LEG1_PROD_INITIAL_LONG_POS' : 'LEG1_PROD_INITIAL_SHORT_POS'}`, toNumberInputValue(e.target.value))
                            onChangeProfile(newProfile)
                        }} />}
                    <span className='quote-symbol-table--data-slash-symbol'>{'|'}</span>
                    <Popup className='quote-symbol-table--position-data--pos-notional-popup'
                        on={'hover'}
                        trigger={
                            <input 
                                className={'quote-symbol-table--position-data--max-side-pos-input' + (isSymbolQuantityCapped ? ' warning-red' : '')}
                                type={'number'}
                                title={'Max Position'} 
                                value ={!_.isNil(maxSidePositionValue) ? maxSidePositionValue : ''}
                                onKeyDown={(e) => { this.handleKeyDownInput(e) }}
                                onBlur={(e) => {
                                    if (e.target.value === '' || _.isNaN(Number(e.target.value))) {
                                        const quoteMaxSidePos = _.cloneDeep(symbolItem.params.QUOTE_MAX_SIDE_POS) || [0, 0]
                                        quoteMaxSidePos[parameterIndex] = 0
                                        const newProfile = dotProp.set(profile, `legs.1.symbols.${index}.params.QUOTE_MAX_SIDE_POS`, quoteMaxSidePos)
                                        onChangeProfile(newProfile)
                                    }
                                }}
                                onWheel={(e) => { e.target.blur() }}
                                onChange={(e) => {
                                    const quoteMaxSidePos = _.cloneDeep(symbolItem.params.QUOTE_MAX_SIDE_POS) || [0, 0]
                                    quoteMaxSidePos[parameterIndex] = toNumberInputValue(e.target.value)
                                    const newProfile = dotProp.set(profile, `legs.1.symbols.${index}.params.QUOTE_MAX_SIDE_POS`, quoteMaxSidePos)
                                    onChangeProfile(newProfile)
                                }} />
                        }>
                        {'Notional: ' + (maxSidePosNotional ? `${toNumberWithSmartPrecision({ number: maxSidePosNotional, shouldReturnLocalString: true })} ${quote}` : 'N/A')}
                    </Popup>
                </td>
            )
        }

        const renderOpenPosition = (direction='BUY') => {
            const { open_long: openLong, open_short: openShort } = symbolPositionData[symbolAccountName[direction]] || {}
            
            const open = direction === 'BUY' ? Number(toFixedNumber(openLong, 4))
                : direction === 'SELL' ? Number(toFixedNumber(openShort, 4))
                : null
            const openNotional = !_.isNil(open)
                ? getNotional({
                    symbolItem: symbol.items[symbolItem.name],
                    quantity: open,
                    price: lastPrice,
                    BTCUSDIndexLastPrice: _.get(symbol.pricings, 'btc_usdc_BINANCE_SPT.last')
                })
                : null
            return (
                <td className={`quote-symbol-table--open-position ${direction}`}>
                    <div>{open}</div>
                    {Number(open) !== 0 && !_.isNil(openNotional) && <div>{`${toNumberWithSmartPrecision({ number: openNotional, shouldReturnLocalString: true, defaultPrecision: quote === 'BTC' ? 2 : 0 })} ${quote}`}</div>}
                </td>
            )
        }

        const renderSwitches = (direction='BUY') => {
            return (
                <td className={`quote-symbol-table--symbol-switch ${direction}`}>
                    <ProfileSwitches 
                        profileItem={profile}
                        symbolName={symbolItem.name} 
                        direction={direction} />    
                </td>
            )
        }

        const renderReduceOnlySwitches = (direction='BUY') => {
            return (
                <td className={`quote-symbol-table--symbol-reduce-only-switch ${direction}`}>
                    <ProfileReduceOnlySwitches 
                        profileItem={profile} 
                        symbolName={symbolItem.name} 
                        direction={direction} />
                </td>
            )
        }

        const renderFundingRate = () => {
            const symbolFundingRate = symbol.fundingRates[symbolItem.name]
            const symbolFundingRateInHouse = symbol.fundingRatesInHouse[symbolItem.name]
            const { exchangeName } = getSymbolAttributeByName(symbolItem.name)
            
            if (_.isEmpty(symbolFundingRate) && _.isEmpty(symbolFundingRateInHouse)) {
                return null
            } else {
                const isDynamicFundingRate = isDynamicFundingRateSymbol(symbolItem.name)
                const currentFundingRate = _.get(symbolFundingRate, 'current_funding_rate')
                const indicativeFundingRate = _.get(symbolFundingRate, 'indicative_funding_rate')
                const inhouseFundingRate = _.get(symbolFundingRateInHouse, 'funding_rate_calc')
                const inhouseTimeWeightedFundingRate = _.get(symbolFundingRateInHouse, 'funding_rate_calc_tw')
                const precision = Math.max(getSymbolFundingRatePrecision(symbolItem.name) - 2, 2)
                const interval = EXCHANGES_SHOULD_SHOW_FUNDING_INTERVAL.includes(exchangeName) && _.every([indicativeFundingRate, currentFundingRate], v => !BigNumber(v ?? 0).eq(0)) ? BigNumber(indicativeFundingRate).times(8).div(currentFundingRate).toFixed(0) : null

                return (
                    <div className='quote-symbol-table--funding-rate'>
                        {!_.isNil(currentFundingRate) &&
                        <div className='quote-symbol-table--funding-block current-funding'>
                            <span className='quote-symbol-table--funding-block--time'>
                                {symbolFundingRate.timestamp && !isDynamicFundingRate ? moment(new Date(symbolFundingRate.timestamp)).format('HH:mm') : 'Current'}
                            </span>
                            <div className={'quote-symbol-table--funding-block--value ' + (currentFundingRate > 0 ? 'positive' : 'negative')}>
                                {(Number(currentFundingRate) * 10000).toFixed(precision) + '%%'}
                            </div>
                        </div>}
                        {!_.isNil(inhouseFundingRate) &&
                        <div className='quote-symbol-table--funding-block in-house-rate'>
                            <span className='quote-symbol-table--funding-block--time'>
                                {`In-house ${moment(symbolFundingRateInHouse?.next_funding_time).format('HH:mm')}`}
                            </span>
                            <div className={'quote-symbol-table--funding-block--value ' + (inhouseFundingRate > 0 ? 'positive' : 'negative')}>
                                {(Number(inhouseFundingRate) * 10000).toFixed(precision) + '%%'}
                            </div>
                        </div>}
                        {!_.isNil(inhouseTimeWeightedFundingRate) &&
                        <div className='quote-symbol-table--funding-block in-house-time-weighted-rate'>
                            <span className='quote-symbol-table--funding-block--time'>
                                {`In-house TW`}
                            </span>
                            <div className={'quote-symbol-table--funding-block--value ' + (inhouseTimeWeightedFundingRate > 0 ? 'positive' : 'negative')}>
                                {(Number(inhouseTimeWeightedFundingRate) * 10000).toFixed(precision) + '%%'}
                            </div>
                        </div>}
                        {!_.isNil(indicativeFundingRate) &&
                        <div className='quote-symbol-table--funding-block next-funding'>
                            <span className='quote-symbol-table--funding-block--time'>
                                {symbolFundingRate.next_period && !isDynamicFundingRate ? `${moment(new Date(symbolFundingRate.next_period)).format('HH:mm')}${!_.isNil(interval) ? ` (${interval}-hour)` : ''}` : 'Next'}
                            </span>
                            <div className={'quote-symbol-table--funding-block--value ' + (indicativeFundingRate > 0 ? 'positive' : 'negative')}>
                                {(Number(indicativeFundingRate) * 10000).toFixed(precision) + '%%'}
                            </div>
                        </div>}
                    </div>
                )
            }
        }

        return (
            <Fragment key={index}>
                <tr>
                    <td rowSpan={2} className='quote-symbol-table--symbol-name'>
                        <div rowSpan={2}>
                            {symbol.items[symbolItem.name] 
                            ? <SymbolPopup
                                symbolName={symbolItem.name}
                                profileId={profile.id} />
                            : symbolItem.name}
                            {Number(symbolPositionNotionalSum) !== 0 && 
                            <div className='quote-symbol-table--symbol-name--position-notional-sum' title={'Position Notional Sum'}>
                                <span className={symbolPositionNotionalSum > 0 ? 'positive' : 'negative'}>{`${toNumberWithSmartPrecision({ number: symbolPositionNotionalSum, shouldReturnLocalString: true, defaultPrecision: quote === 'BTC' ? 2 : 0 })}`}</span>
                                {quote}
                            </div>}
                        </div>
                        {renderRefSymbols()}
                        {!_.isEmpty(fxSymbol) && fxSymbol !== 'INVALID' &&
                        <div className='quote-symbol-table--fx-symbol'>
                            <label>{'FX Symbol'}</label>
                            <span>{fxSymbol}</span>
                        </div>}
                    </td>
                    {hasSymbolWithFundingRate && <td rowSpan={2}>{renderFundingRate()}</td>}
                    {hasLiquidationColumn && <td rowSpan={2}>
                        {((instrumentType === INSTRUMENT_TYPES.SPOT && _.has(symbolItem, 'params') 
                            && _.isArray(symbolItem.params.QUOTE_MARGIN_TRADE_ENABLED)
                            && _.isArray(symbolItem.params.QUOTE_CROSS_MARGIN_TRADE_ENABLED)
                            && (_.some(symbolItem.params.QUOTE_MARGIN_TRADE_ENABLED, enabled => enabled === true) 
                                || _.some(symbolItem.params.QUOTE_CROSS_MARGIN_TRADE_ENABLED, enabled => enabled === true))
                            ) 
                        || ([INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.SWAP].includes(instrumentType)))
                        ? <ProfileLiquidations 
                        symbolName={symbolItem.name} 
                        accountNames={unionTradingAccountNames} />
                        : null}
                    </td>}
                    <td className='quote-symbol-table--direction-label BUY'>{'Buy'}</td>
                    {renderSwitches('BUY')}
                    {hasReduceOnlyColumn && renderReduceOnlySwitches('BUY')}
                    {renderPricingData('BUY')}
                    {hasRefSymbol && renderRefPricingData('BUY')}
                    {renderSpreadData('BUY')}
                    {shouldShowSpreadDiffData && renderAdjustedSpreadThresholdDelta()}
                    {hasRefSymbol && renderRefSpreadData('BUY')}
                    {renderPositionData('BUY')}
                    <td rowSpan={2} className={'quote-symbol-table--net-position-value' + 
                        (netPosition > 0 ? ' positive' 
                        : netPosition < 0 ? ' negative' 
                        : '')}>
                        {netPosition ? toNumberWithSmartPrecision({ number: netPosition, shouldReturnLocalString: true }) : 0}
                        {Number(netPositionNotional) !== 0 && <div className='quote-symbol-table--net-position-value--notional'>{`(${toNumberWithSmartPrecision({ number: netPositionNotional, shouldReturnLocalString: true, defaultPrecision: quote === 'BTC' ? 2 : 0 })} ${quote})`}</div>}
                    </td>
                    {shouldShowOpenSize && renderOpenPosition('BUY')}
                </tr>
                <tr>
                    <td className='quote-symbol-table--direction-label SELL'>{'Sell'}</td>
                    {renderSwitches('SELL')}
                    {hasReduceOnlyColumn && renderReduceOnlySwitches('SELL')}
                    {renderPricingData('SELL')}
                    {hasRefSymbol && renderRefPricingData('SELL')}
                    {renderSpreadData('SELL')}
                    {hasRefSymbol && renderRefSpreadData('SELL')}
                    {renderPositionData('SELL')}
                    {shouldShowOpenSize && renderOpenPosition('SELL')}
                </tr>
            </Fragment>
        )
    }

    render () {
        const { profile, positions, account, shouldShowSpreadDiffData, shouldShowOpenSize } = this.props
        const hasRefSymbol = this._hasRefSymbol()
        const hasSymbolWithFundingRate = this._hasSymbolWithFundingRate()
        const hasAdjustedThreshold = this._hasAdjustedThreshold()
        const hasLiquidationColumn = shouldProfileLegHaveLiquidationColumn({ profileItem: profile, legKey: '1', positionItems: positions, marginAccountBalances: account.balance.margin })
        const hasReduceOnlyColumn = this._hasReduceOnlyColumn()
        
        return (
            <table className='quote-symbol-table'>
                <thead>
                    <tr>
                        <th className='quote-symbol-table--header symbols'><LegPopup profile={profile} legNumber={1} /></th>
                        {hasSymbolWithFundingRate && <th>{'Funding %'}</th>}
                        {hasLiquidationColumn && <th>{'Liquidation'}</th>}
                        <th>{'Direction'}</th>
                        <th><span>{'Trading'}</span></th>
                        {hasReduceOnlyColumn && <th>{'Reduce-Only'}</th>}
                        <th className='quote-symbol-table--header pricing'>
                            <span>{'Pricing'}</span>
                            <span className='quote-symbol-table--header-slash-symbol'>{'|'}</span>
                            <Popup 
                                className='quote-symbol-table--header-popup'
                                trigger={<span className='quote-symbol-table--header-text-with-description'>{'Theo Price'}</span>}>
                                <div><strong>{'Theoretical Buy Price'}</strong>{': Buy only when Bid/Ask is below this specified price'}</div>
                                <div><strong>{'Theoretical Sell Price'}</strong>{': Sell only when Bid/Ask is above this specified price'}</div>
                            </Popup>
                            <span className='quote-symbol-table--header-slash-symbol'>{'|'}</span>
                            <span>{'Adjusted'}</span>
                            {shouldShowSpreadDiffData &&
                            <>
                                <span className='quote-symbol-table--header-slash-symbol'>{'|'}</span>
                                <Popup 
                                    className='quote-symbol-table--header-popup'
                                    trigger={<span className='quote-symbol-table--header-text-with-description'>{'Adj. Pr. DEV'}</span>}>
                                    <div><strong>{'Adjusted Price Deviation'}</strong>{` represents the difference between the best Bid/Ask price and the adjusted theory price.`}</div>
                                    <div><strong>{'Buy Side'}</strong>{' = Ask Price - Theory Buy Price'}</div>
                                    <div><strong>{'Sell Side'}</strong>{' = Theory Sell Price - Bid Price'}</div>
                                </Popup>
                            </>}
                        </th>
                        {hasRefSymbol && <th className='quote-symbol-table--header pricing'>
                            <span>{'Ref PC.'}</span>
                            <span className='quote-symbol-table--header-slash-symbol'>{'|'}</span>
                            <span>{'Ref Theo PC.'}</span>
                        </th>}
                        <th className='quote-symbol-table--header spread'>
                            {shouldShowSpreadDiffData &&
                            <>
                                <Popup 
                                    className='quote-symbol-table--header-popup'
                                    trigger={<span className='quote-symbol-table--header-text-with-description'>{'Spd. Diff'}</span>}>
                                    <div><strong>{'Spread Difference'}</strong>{` represents the difference between the spread and the adjusted spread threshold. `}</div>
                                    <div><strong>{'On the Ask side'}</strong>{', Spd. Diff = Spread - Adjusted Spread'}</div>
                                    <div><strong>{'On the Bid side'}</strong>{', Spd. Diff = Adjusted Spread - Spread'}</div>
                                </Popup>
                                <span className='quote-symbol-table--header-slash-symbol'>{'|'}</span>
                            </>}
                            {'Spread'}
                            <span className='quote-symbol-table--header-slash-symbol'>{'|'}</span>
                            {hasAdjustedThreshold &&
                            <Fragment>
                                <Popup 
                                    className='quote-symbol-table--header-popup'
                                    trigger={<span className='quote-symbol-table--header-text-with-description'>{'Adjusted'}</span>}>
                                    <div dangerouslySetInnerHTML={{__html: `
                                        The Adjusted Spread Threshold is effective when<br/><br/> 
                                        a <i>SWAP</i> is selected AND <i>Spread Threshold Type</i> is <i>RATIO/PREMIUM</i>
                                        AND <i>Spread Threshold Ajudst By Funding</i> is enabled<br/><br/>
                                        <b>OR</b> <i>Spead Threshold Adjust Base Pos</i> is non-zero<br/><br/>
                                        <b>OR</b> <i>FX Symbol</i> is selected.
                                    `}} />
                                </Popup>
                                <span className='quote-symbol-table--header-slash-symbol'>{'|'}</span>
                            </Fragment>}
                            <Popup 
                                className='quote-symbol-table--header-popup'
                                trigger={<span className='quote-symbol-table--header-text-with-description'>{'Threshold'}</span>}>
                                <div>{'Buy only when '}<strong>{'Spread'}</strong>{' is below '}<strong>{'Buy Spread Threshold'}</strong></div>
                                <div>{'Sell only when '}<strong>{'Spread'}</strong>{' is above '}<strong>{'Sell Spread Threshold'}</strong></div>
                            </Popup>
                            {shouldShowSpreadDiffData &&
                            <>
                                <span className='quote-symbol-table--header-slash-symbol'>{'|'}</span>
                                <Popup 
                                    className='quote-symbol-table--header-popup'
                                    trigger={<span className='quote-symbol-table--header-text-with-description'>{'Adj. Thr. Diff'}</span>}>
                                    <div><strong>{`Adjusted Threshold Difference`}</strong>{` represents the difference between adjusted spread threshold and specified threshold given by the trader. `}</div>
                                    <div><strong>{'Adj. Thr. Diff'}</strong>{' = Adjusted Spread Threshold - Spread Threshold'}</div>
                                </Popup>
                            </>}
                        </th>
                        {shouldShowSpreadDiffData &&
                        <th className='quote-symbol-table--header adjusted-spread-threshold-delta'>
                            <Popup 
                                className='quote-symbol-table--header-popup'
                                trigger={<span className='quote-symbol-table--header-text-with-description'>{'Adj. Thr. Delta'}</span>}>
                                <div><strong>{'Adjusted Threshold Delta'}</strong>{' indicates the difference between Adjusted Spread Threshold in sell side and Adjusted Spread Threshold in buy side'}</div>
                            </Popup>
                        </th>}
                        {hasRefSymbol && <th className='quote-symbol-table--header ref-spread'>
                            {'Ref SPD'}
                            <span className='quote-symbol-table--header-slash-symbol'>{'|'}</span>
                            <Popup 
                                className='quote-symbol-table--header-popup'
                                trigger={<span className='quote-symbol-table--header-text-with-description'>{'Ref THR'}</span>}>
                                <div>{'Buy only when '}<strong>{'Ref Spread'}</strong>{' is below '}<strong>{'Ref Buy Spread Threshold'}</strong></div>
                                <div>{'Sell only when '}<strong>{'Ref Spread'}</strong>{' is above '}<strong>{'Ref Sell Spread Threshold'}</strong></div>
                            </Popup>
                        </th>}
                        <th className='quote-symbol-table--header positions'>
                            {profile.started || profile.isStarting ? 'V Pos' : 'Int Pos'}
                            <span className='quote-symbol-table--header-slash-symbol'>{'|'}</span>
                            <Popup 
                                className='quote-symbol-table--header-popup'
                                trigger={<span className='quote-symbol-table--header-text-with-description'>{'Max Pos'}</span>}>
                                <div><strong>{'Max Side Position: '}</strong>{'Defines the maximum position in the Buy/Sell side'}</div>
                            </Popup>
                        </th>
                        <th className='quote-symbol-table--header net-position'>{'Net Pos'}</th>
                        {shouldShowOpenSize && <th className='quote-symbol-table--header open-position'>{'Open'}</th>}
                    </tr>
                </thead>
                <tbody>{(profile.legs[1].symbols || []).map((symbolItem, index) => this.renderSymbol(symbolItem, index))}</tbody>
            </table>
        )
    }
}

QuoteSymbolTable.propTypes = {
    profile: PropTypes.object.isRequired,
    symbol: PropTypes.object.isRequired,
    runningState: PropTypes.object.isRequired,
    positions: PropTypes.array.isRequired,
    account: PropTypes.object.isRequired,
    shouldShowSpreadDiffData: PropTypes.bool.isRequired,
    shouldShowOpenSize: PropTypes.bool.isRequired,
    onChangeProfile: PropTypes.func.isRequired,
    onKeyDownEnter: PropTypes.func.isRequired
}

QuoteSymbolTable.defaultProps = {
    shouldShowSpreadDiffData: false,
    shouldShowOpenSize: false,
    onChangeProfile: () => {},
    onKeyDownEnter: () => {},
    onChangeHeight: () => {}
}

function mapStateToProps (state, ownProps) {
    return {
        symbol: state.symbol,
        runningState: state.profile.runningState[ownProps.profile.id] || {},
        positions: state.trading.positions,
        account: state.account
    }
}

export default connect(mapStateToProps)(QuoteSymbolTable)