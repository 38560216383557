import React, { memo, useMemo, useRef, useState } from 'react'
import { useLatest, useMount, useMountedState, useSessionStorage, useUpdateEffect } from 'react-use'
import useEvent from 'react-use-event-hook'
import { useDispatch } from 'react-redux'
import randomColor from 'randomcolor'
import { nanoid } from 'nanoid'
import moment from 'moment'
import _ from 'lodash'

import ReactEChartsCore from 'echarts-for-react/lib/core'
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core'
// Import charts, all with Chart suffix
import {
    LineChart
    // BarChart,
    // PieChart,
    // ScatterChart,
    // RadarChart,
    // MapChart,
    // TreeChart,
    // TreemapChart,
    // GraphChart,
    // GaugeChart,
    // FunnelChart,
    // ParallelChart,
    // SankeyChart,
    // BoxplotChart,
    // CandlestickChart,
    // EffectScatterChart,
    // LinesChart,
    // HeatmapChart,
    // PictorialBarChart,
    // ThemeRiverChart,
    // SunburstChart,
    // CustomChart,
} from 'echarts/charts'

import {
    // GridSimpleComponent,
    GridComponent,
    // PolarComponent,
    // RadarComponent,
    // GeoComponent,
    // SingleAxisComponent,
    // ParallelComponent,
    // CalendarComponent,
    // GraphicComponent,
    // ToolboxComponent,
    TooltipComponent,
    AxisPointerComponent,
    // BrushComponent,
    TitleComponent,
    // TimelineComponent,
    // MarkPointComponent,
    // MarkLineComponent,
    // MarkAreaComponent,
    // LegendComponent,
    // LegendScrollComponent,
    // LegendPlainComponent,
    // DataZoomComponent,
    DataZoomInsideComponent,
    // DataZoomSliderComponent,
    // VisualMapComponent,
    // VisualMapContinuousComponent,
    // VisualMapPiecewiseComponent,
    // AriaComponent,
    // TransformComponent,
    DatasetComponent
} from 'echarts/components'

import {
    CanvasRenderer
    // SVGRenderer,
} from 'echarts/renderers'

// Register the required components
echarts.use([TitleComponent, TooltipComponent, AxisPointerComponent, GridComponent, LineChart, DataZoomInsideComponent, DatasetComponent, CanvasRenderer])

import { FaXmark } from 'react-icons/fa6'
import Popup from '../common/popup/Popup'
import Checkbox from '../common/checkbox/Checkbox'

import { areAllValuesNonEmpty, isMetSearchStringCriteria } from '../../util/util'
import { fetchBorrowRateHistory, fetchBorrowRatePairs } from './tradingAction'
import BigNumber from 'bignumber.js'

const SECONDS_PER_DAY = 86400

const TIME_RANGES = {
    D1: {
        key: 'D1',
        name: '1 Day',
        duration: SECONDS_PER_DAY
    },
    D3: {
        key: 'D3',
        name: '3 Days',
        duration: SECONDS_PER_DAY * 3
    },
    D7: {
        key: 'D7',
        name: '7 Days',
        duration: SECONDS_PER_DAY * 7
    },
    D30: {
        key: 'D30',
        name: '30 Days',
        duration: SECONDS_PER_DAY * 30
    },
    D60: {
        key: 'D60',
        name: '60 Days',
        duration: SECONDS_PER_DAY * 60
    },
    D90: {
        key: 'D90',
        name: '90 Days',
        duration: SECONDS_PER_DAY * 90
    }
}

function BorrowRateChart () {

    const dispatch = useDispatch()
    const isMounted = useMountedState()

    const [timeRange, setTimeRange] = useSessionStorage('borrow-rate-chart--time-range-1', TIME_RANGES.D3.key)
    const [exchangeCoinPairs, setExchangeCoinPairs] = useState([['okex', 'ADA'], ['binance', 'BTC']])
    const [selectedExchangeCoinPairs, setSelectedExchangeCoinPairs] = useSessionStorage(`borrow-rate-chart--selected-exchange-coin-pairs`, [])
    const [exchangeCoinPairSearchString, setExchangeCoinPiarSearchString] = useState('')
    const [borrowRatesPerPair, setBorrowRatesPerPair] = useState({})
    const [borrowRatesUpdatedId, setBorrowRatesUpdatedId] = useState(0)
    const [chartId, setChartId] = useState(0)
    const [colorPerPairKey, setColorPerPairKey] = useState({})
    const [shouldAccumulate, setShouldAccumulate] = useSessionStorage(`borrow-rate-chart--should-accumulate`, false)
    const [isFetching, setIsFetching] = useState(false)
    const latestIsFetching = useLatest(isFetching)

    const chart = useRef(null)

    const selectedExchangeCoinPairsDependence = JSON.stringify(selectedExchangeCoinPairs)

    const filteredExchangeCoinPairs = useMemo(() => {
        return _.filter(exchangeCoinPairs, _pair => isMetSearchStringCriteria(`${_pair[0]} ${_pair[1]}`, exchangeCoinPairSearchString))
    }, [_.size(exchangeCoinPairs), exchangeCoinPairSearchString])

    const _fetchBorrowRates = useEvent((_pairs) => {
        const __pairs = _pairs || selectedExchangeCoinPairs
        if (!_.isEmpty(__pairs)) {
            const from = moment().add(
                (_.maxBy(_.values(TIME_RANGES), 'duration')?.duration ?? 0) * -1,
                'seconds'
            )
            if (!isFetching) {
                setIsFetching(true)
            }
            dispatch(fetchBorrowRateHistory({ from, pairs: __pairs }))
            .then((_borrowRates) => {
                const _sortedBorrowRates = _.sortBy(_borrowRates, _rate => moment(_rate.time).unix())
                setBorrowRatesPerPair((prevRates) => {
                    return {
                        ...prevRates,
                        ..._.groupBy(_sortedBorrowRates, _rate => `${_rate.exchange}-${_rate.coin}`)
                    }
                })
                setBorrowRatesUpdatedId(borrowRatesUpdatedId + 1)
            })
            .finally(() => {
                if (isMounted() && latestIsFetching) {
                    setIsFetching(false)
                }
            })
        }
    })

    const _updateColorPerPairKey = useEvent((_shouldForceUpdate=false) => {
        const _newColorPerPairKey = _.cloneDeep(colorPerPairKey)
        let _shouldUpdate = false

        _.forEach(selectedExchangeCoinPairs, _pair => {
            const _key = `${_pair[0]}-${_pair[1]}`
            if (_shouldForceUpdate || !_.has(_newColorPerPairKey, _key)) {
                _newColorPerPairKey[_key] = randomColor()
                _shouldUpdate = true
            }
        })

        if (_shouldUpdate) {
            setColorPerPairKey(_newColorPerPairKey)
        }
    })

    useMount(() => {
        dispatch(fetchBorrowRatePairs())
        .then((_pairs) => {
            if (isMounted()) {
                setExchangeCoinPairs(_.map(_pairs, _p => [_p.exchange, _p.coin]))
            }
        })

        _fetchBorrowRates()
        _updateColorPerPairKey()
    })

    useUpdateEffect(() => {
        _updateColorPerPairKey()
    }, [selectedExchangeCoinPairsDependence])

    const { chartDataset } = useMemo(() => {
        const _dataset = {
            timestamps: [],
            borrowRatesPerPair: {}
        }
    
        const _fromTimestamp = moment().add((_.get(TIME_RANGES, `${timeRange}.duration`) ?? SECONDS_PER_DAY) * -1, 'seconds').toISOString()
        const _timestamps = _.reduce(selectedExchangeCoinPairs, (_result, _pair) => {
            const _borrowRates = borrowRatesPerPair[`${_pair[0]}-${_pair[1]}`]
            _.forEach(_borrowRates, _borrowRate => {
                if (!_.isEmpty(_borrowRate.time) && moment(_borrowRate.time).isSameOrAfter(_fromTimestamp)) {
                    _result[_borrowRate.time] = 1
                }
            })
            return _result
        }, {})
        
        _dataset.timestamps = _.keys(_timestamps)
        _dataset.timestamps.sort()
        
        _.forEach(selectedExchangeCoinPairs, _pair => {
            const _key = `${_pair[0]}-${_pair[1]}`
            const _borrowRates = borrowRatesPerPair[_key]
            const _borrowRatesPerTimestamp = _.keyBy(_borrowRates, _rate => _rate.time)
            _dataset.borrowRatesPerPair[_key] = []
            _.forEach(_dataset.timestamps,  _timestamp => {
                let _value = _borrowRatesPerTimestamp[_timestamp]?.previous_rate_bps
                if (shouldAccumulate) {
                    _value = Number(_value || 0) + Number(_.last(_dataset.borrowRatesPerPair[_key]) || 0)
                }
                
                _dataset.borrowRatesPerPair[_key].push(_value)
            })
        })

        return {
            chartId: nanoid(),
            chartDataset: _dataset
        }
    }, [timeRange, borrowRatesUpdatedId, selectedExchangeCoinPairsDependence, shouldAccumulate])
    
    return (
        <div className='borrow-rate-chart'>
            <div className='borrow-rate-chart--criterias'>
                <div className='borrow-rate-chart--criteria time-range'>
                    <label>{'Time Range'}</label>
                    <div className='borrow-rate-chart--time-ranges'>
                        {_.map(TIME_RANGES, _timeRange => (
                            <button key={_timeRange.key}
                                className={_timeRange.key === timeRange ? 'selected' : null}
                                onClick={() => { setTimeRange(_timeRange.key) }}>
                                {_timeRange.name}
                            </button>
                        ))}
                    </div>
                </div>
                <div className='borrow-rate-chart--criteria exchange-coin-pairs'>
                    <label>{'Exchange-Coin'}</label>
                    <div className='borrow-rate-chart--exchange-coin-pairs'>
                        <div className='borrow-rate-chart--exchange-coin-pairs--buttons'>
                            <Popup className='borrow-rate-chart--exchange-coin-pairs-popup'
                                on={'click'}
                                trigger={<button className='borrow-rate-chart--exchange-coin-pairs-popup--trigger'>{'Select Pairs'}</button>}>
                                <div className='borrow-rate-chart--exchange-coin-pairs-popup--head'>
                                    <input className='borrow-rate-chart--exchange-coin-pairs-popup--search-input'
                                        value={exchangeCoinPairSearchString}
                                        spellCheck={false}
                                        placeholder='Search Exchange, Coin'
                                        onChange={(e) => { setExchangeCoinPiarSearchString(e.target.value) }} />
                                    <button className='borrow-rate-chart--exchange-coin-pairs-popup--select-all-button'
                                        disabled={_.isEmpty(filteredExchangeCoinPairs)}
                                        onClick={() => {
                                            const _newSelectedPairs = _.unionBy(selectedExchangeCoinPairs, filteredExchangeCoinPairs, _pair => `${_pair[0]}--${_pair[1]}`)
                                            setSelectedExchangeCoinPairs(_newSelectedPairs)
                                            const _pairsShouldFetchData = _.filter(_newSelectedPairs, _pair => !_.has(borrowRatesPerPair, `${_pair[0]}-${_pair[1]}`))
                                            _fetchBorrowRates(_pairsShouldFetchData)
                                            setChartId(chartId + 1)
                                       }}>{'Select All'}</button>
                                </div>
                                <div className='borrow-rate-chart--exchange-coin-pairs-popup--list'>
                                    {_.map(filteredExchangeCoinPairs, (_pair, _index) => {
                                        const selectedIndex = _.findIndex(selectedExchangeCoinPairs, _p => _.isEqual(_pair, _p))
                                        return (
                                            <div key={_index}
                                                className='borrow-rate-chart--exchange-coin-pairs-popup--item'
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    const _newSelectedPairs = selectedIndex < 0
                                                        ? _.concat(selectedExchangeCoinPairs, [_pair])
                                                        : _.concat(
                                                            selectedExchangeCoinPairs.slice(0, selectedIndex),
                                                            selectedExchangeCoinPairs.slice(selectedIndex + 1)
                                                        )
                                                    setSelectedExchangeCoinPairs(_newSelectedPairs)

                                                    if (selectedIndex < 0 && !_.has(borrowRatesPerPair, `${_pair[0]}-${_pair[1]}`)) {
                                                        _fetchBorrowRates([_pair])
                                                    }

                                                    setChartId(chartId + 1)
                                                }}>
                                                <Checkbox checked={selectedIndex > -1} />
                                                <label>{`${_pair[0]} - ${_pair[1]}`}</label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Popup>
                            <button className='borrow-rate-chart--exchange-coin-pairs--clear-button'
                                disabled={_.isEmpty(selectedExchangeCoinPairs)}
                                onClick={() => { setSelectedExchangeCoinPairs([]) }}>
                                {'Clear'}
                            </button>
                            <button className='borrow-rate-chart--exchange-coin-pairs--shuffle-color-button'
                                disabled={_.isEmpty(selectedExchangeCoinPairs)}
                                onClick={() => { _updateColorPerPairKey(true) }}>
                                {'Shuffle Colors'}
                            </button>
                            <button className='borrow-rate-chart--exchange-coin-pairs--refetch-button'
                                disabled={_.isEmpty(selectedExchangeCoinPairs) || isFetching}
                                onClick={() => { _fetchBorrowRates(selectedExchangeCoinPairs) }}>
                                {isFetching ? 'Fetching...' : 'Re-fetch All Data'}
                            </button>
                            <div className='borrow-rate-chart--exchange-coin-pairs--should-accumulate-toggle'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShouldAccumulate(!shouldAccumulate)
                                }}>
                                <Checkbox checked={shouldAccumulate} />
                                <label>{'Should Accumulate'}</label>
                            </div>
                        </div>
                        <div className='borrow-rate-chart--exchange-coin-pairs--selected-list'>
                            {_.map(selectedExchangeCoinPairs, (_pair, _index) => (
                                <div className='borrow-rate-chart--exchange-coin-pairs--item' key={_index}
                                    style={{ background: colorPerPairKey[`${_pair[0]}-${_pair[1]}`] }}>
                                    <span>{`${_pair[0]} - ${_pair[1]}`}</span>
                                    <button onClick={() => {
                                        const _newPairs = _.cloneDeep(selectedExchangeCoinPairs)
                                        _newPairs.splice(_index, 1)
                                        setSelectedExchangeCoinPairs(_newPairs)
                                        setChartId(chartId + 1)
                                    }}><FaXmark /></button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='borrow-rate-chart--chart-wrapper'>
                <ReactEChartsCore
                    key={chartId}
                    className='borrow-rate-chart--chart'
                    echarts={echarts}
                    option={{
                        dataset: {
                            source: {
                                timestamps: chartDataset.timestamps,
                                ...(chartDataset.borrowRatesPerPair)
                            }
                        },
                        dataZoom: [{
                            type: 'inside',
                            disabled: false,
                            animation: false,
                            zoomOnMouseWheel: true
                        }],
                        textStyle: {
                            fontWeight: 500
                        },
                        tooltip: {
                            show: true,
                            trigger: 'axis',
                            textStyle: {
                                fontSize: 13,
                                color: 'white'
                            },
                            padding: [6, 12],
                            backgroundColor: 'black',
                            borderColor: 'none',
                            borderWidth: 0,
                            extraCssText: 'box-shadow: unset; line-height: 24px;',
                            valueFormatter: (value) => `${BigNumber(value).toFormat(shouldAccumulate ? 2 : 4)}%%`,
                            axisPointer: {
                                axis: 'x',
                                lineStyle: {
                                    type: 'line',
                                    width: 1,
                                    color: '#ddd',
                                    opacity: 0.6
                                },
                                snap: true
                            }
                            // formatter: tooltipFormatter,
                            // position: tooltipPosition
                        },
                        grid: {
                            left: 30,
                            right: 0,
                            top: 10,
                            bottom: 30
                        },
                        xAxis: {
                            type: 'time',
                            boundaryGap: ['1%', '1%'],
                            show: true,
                            name: ``,
                            nameLocation: 'center',
                            nameGap: 20,
                            nameTextStyle: {
                                color: '#ddd',
                                fontSize: 12
                            },
                            inverse: false,
                            splitNumber: 4,
                            splitLine: {
                                show: false
                            },
                            axisLine: {
                                lineStyle: {
                                    opacity: 0.1,
                                    color: 'white'
                                }
                            },
                            axisLabel: {
                                color: '#aaa',
                                fontSize: 11,
                                fontWeight: 600,
                                formatter: (value) => `${moment(value).format('MMM D')}`
                            },
                            axisTick: {
                                show: true,
                                length: 3,
                                lineStyle: {
                                    opacity: 0.3,
                                    color: 'white'
                                }
                            }
                        },
                        yAxis: {
                            show: true,
                            boundaryGap: false,
                            z: 1,
                            name: shouldAccumulate ? `Accumulated Rates` : `Borrow Rate`,
                            nameLocation: 'middle',
                            nameGap: 10,
                            nameTextStyle: {
                                align: 'center',
                                color: '#ddd',
                                fontSize: 13,
                                fontWeight: 600
                            },
                            type: 'value',
                            splitNumber: 5,
                            axisLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            },
                            axisLabel: {
                                inside: true,
                                margin: 4,
                                color: '#aaa',
                                fontSize: 11,
                                fontWeight: 600,
                                verticalAlign: 'bottom',
                                showMaxLabel: false,
                                formatter: (value) => `${BigNumber(value).toFormat(shouldAccumulate ? 2 : 4)}%%`
                            },
                            axisPointer: {
                                show: false
                            },
                            splitLine: {
                                lineStyle: {
                                    color: ['white'],
                                    width: 0.5,
                                    type: 'dashed',
                                    opacity: 0.2
                                }
                            },
                            min: (value) => {
                                if (areAllValuesNonEmpty([value?.min, value?.max])) {
                                    return value.min === value.max ? 0 : Math.max(0, value.min - (value.max - value.min) * 0.15)
                                }
                            },
                            max: (value) => {
                                if (areAllValuesNonEmpty([value?.min, value?.max])) {
                                    return value.min === value.max ? Number(value.max) * 2 : value.max + (value.max - value.min) * 0.15
                                }
                            }
                        },
                        series: _.map(chartDataset.borrowRatesPerPair, (_borrowRates, _key) => ({
                            type: 'line',
                            id: _key,
                            name: _key,
                            symbolSize: 0,
                            select: { disabled: true },
                            encode: {
                                x: 'timestamps',
                                y: _key
                            },
                            lineStyle: {
                                color: colorPerPairKey[_key],
                                width: 1.5
                            },
                            itemStyle: {
                                color: colorPerPairKey[_key]
                            },
                            emphasis: {
                                itemStyle: {
                                    borderWidth: 5
                                }
                            },
                            cursor: 'default',
                            large: true,
                            animation: false,
                            animationDuration: 300
                        }))
                    }}
                    notMerge={false}
                    lazyUpdate
                    // opts={{ series: [] }, {replaceMerge: ['series']}}
                    onChartReady={(_chart) => { chart.current = _chart }} />
            </div>
        </div>
    )
}

export default memo(BorrowRateChart)