import { batch } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import { secureFetch, getQueryString } from '../../util/util'
import { DEFAULT_SERVER, ELF_API_BASE_URL } from '../../configs/config'

export const UPDATE_SYMBOL_ITEMS = 'UPDATE_SYMBOL_ITEMS'
export const UPDATE_SYMBOL_ITEM = 'UPDATE_SYMBOL_ITEM'
export const UPDATE_SYMBOL_DISABLED_ITEMS = 'UPDATE_SYMBOL_DISABLED_ITEMS'
export const UPDATE_SYMBOL_PRICINGS = 'UPDATE_SYMBOL_PRICINGS'
export const UPDATE_SYMBOL_FUNDING_RATES = 'UPDATE_SYMBOL_FUNDING_RATES'
export const UPDATE_SYMBOL_FUNDING_RATES_IN_HOUSE = 'UPDATE_SYMBOL_FUNDING_RATES_IN_HOUSE'
export const UPDATE_OPTION_IMPLIED_VOLATILITIES = 'UPDATE_OPTION_IMPLIED_VOLATILITIES'
export const UPDATE_FUNDING_RATE_HISTORY = 'UPDATE_FUNDING_RATE_HISTORY'
export const UPDATE_FUNDING_RATE_HISTORY_SYMBOLS = 'UPDATE_FUNDING_RATE_HISTORY_SYMBOLS'
export const UPDATE_DEFI_LENDING_INFO = 'UPDATE_DEFI_LENDING_INFO'
export const UPDATE_SYMBOL_ORDER_BOOKS = 'UPDATE_SYMBOL_ORDER_BOOKS'
export const REMOVE_SYMBOL_ORDER_BOOK = 'REMOVE_SYMBOL_ORDER_BOOK'
export const UPDATE_OPTION_SYMBOL_DELTA = 'UPDATE_OPTION_SYMBOL_DELTA'
export const UPDATE_OPTION_COIN_DELTA = 'UPDATE_OPTION_COIN_DELTA'
export const UPDATE_FUNDING_TAGS = 'UPDATE_FUNDING_TAGS'
export const UPDATE_OPTION_SYMBOL_ADDITIONAL_INFO = 'UPDATE_OPTION_SYMBOL_ADDITIONAL_INFO'
export const UPDATE_SYMBOL_PRICE_ALERT_MONITORS = 'UPDATE_SYMBOL_PRICE_ALERT_MONITORS'

export function fetchSymbols (shouldForceUpdateDisabledItems=false) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/symbols`, {
            method: 'GET'
        })).then(response => {
            if (response.status === 200) {
                return response.json()
            }
        }).then(body => {
            const [enabledItems, disabledItems] = _.partition(body.info, symbolItem => _.get(symbolItem, 'trading') === '1' || _.endsWith(symbolItem.symbol_name, '_INDEX'))
            batch(() => {
                dispatch({
                    type: UPDATE_SYMBOL_ITEMS,
                    symbols: _.keyBy(enabledItems, 'symbol_name')
                })
                if (shouldForceUpdateDisabledItems || _.isEmpty(_.get(getState(), 'symbol.disabledItems'))) {
                    dispatch({
                        type: UPDATE_SYMBOL_DISABLED_ITEMS,
                        symbols: _.keyBy(disabledItems, 'symbol_name')
                    })
                }
            })
            resolve(body?.info)
        }).catch(error => {
            console.error(`fetchSymbols error: `, error)
            reject(error)
        })
    })
}

export function updateSymbolItem (symbolItem={}) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SYMBOL_ITEM,
            symbolItem
        })
    }
}

export function fetchSymbolPricings () {
    return (dispatch, getState) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/tickersinfo`, {
            method: 'GET'
        })).then((response) => {
            if (response.status === 200) {
                response.json().then((body) => {
                    if (_.isArray(body.info) && !_.isEmpty(body.info)) {
                        const { pricings } = getState().symbol
                        const newPricings = {}
                        body.info.forEach((symbolItem) => {
                            const symbolName = symbolItem.symbol
                            const pricingItem = pricings[symbolName] || {}
                            const shouldKeepBidAsk = !_.isNil(pricingItem.bidAskUpdateTimestamp) && moment(pricingItem.bidAskUpdateTimestamp).isAfter(symbolItem.ts)
                            newPricings[symbolName] = Object.assign({}, pricingItem || {}, {
                                symbolName,
                                last: symbolItem.last,
                                bid: _.has(pricingItem, 'bid') && shouldKeepBidAsk ? pricingItem.bid : symbolItem.bid,
                                ask: _.has(pricingItem, 'ask') && shouldKeepBidAsk ? pricingItem.ask : symbolItem.ask,
                                timestamp: _.has(pricingItem, 'timestamp') && pricingItem.timestamp > symbolItem.ts ? pricingItem.timestamp : symbolItem.ts
                            })
                        })
                        dispatch(updateSymbolPricings(newPricings))
                    }
                })
            }
        })
    }
}

export function fetchSymbolFundingRates () {
    return (dispatch) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/fundingRates`))
        .then(response => response.json())
        .then(body => {
            dispatch({
                type: UPDATE_SYMBOL_FUNDING_RATES,
                fundingRates: _.keyBy(
                    _.map(body, _fundingRate => ({
                        ..._fundingRate,
                        next_period: moment(_fundingRate.next_period).utcOffset(8, true).toISOString()
                    })), 
                    'symbol'
                )
            })
        })
        .catch(error => {
            console.error(`fetchSymbolFundingRates error`, error)
        })
    }
}

export function fetchSymbolFundingRatesInHouse () {
    return (dispatch) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/funding-rate-in-house`))
        .then(response => response.json())
        .then(body => {
            dispatch({
                type: UPDATE_SYMBOL_FUNDING_RATES_IN_HOUSE,
                fundingRates: _.keyBy(body, 'symbol')
            })
        })
        .catch(error => {
            console.error(`fetchSymbolFundingRates error`, error)
        })
    }
}

export function updateSymbolPricings (pricings) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SYMBOL_PRICINGS,
            pricings
        })
    }
}

export function fetchOptionImpliedVolatilities () {
    return (dispatch) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/option-implied-volatilities`))
        .then(response => response.json())
        .then(body => {
            if (_.isArray(body)) {
                dispatch({
                    type: UPDATE_OPTION_IMPLIED_VOLATILITIES,
                    optionImpliedVolatilities: body
                })
            } else {
                throw new Error('unexpected return')
            }
        })
        .catch(error => {
            console.error('fetchOptionImpliedVolatilities error: ', error)
        })
    }
}

export function fetchOptionSymbolDelta () {
    return (dispatch) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/delta`))
        .then(response => response.json())
        .then(body => {
            if (_.isArray(body)) {
                dispatch({
                    type: UPDATE_OPTION_SYMBOL_DELTA,
                    optionSymbolDelta: _.keyBy(body, 'prod_name')
                })
            } else {
                throw new Error('unexpected return')
            }
        })
        .catch(error => {
            console.error('fetchOptionSymbolDelta error: ', error)
        })
    }
}

export function fetchOptionCoinDelta () {
    return (dispatch) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/sum-delta`))
        .then(response => response.json())
        .then(body => {
            if (_.isArray(body)) {
                dispatch({
                    type: UPDATE_OPTION_COIN_DELTA,
                    optionCoinDelta: _.groupBy(body, item => item.currency.toUpperCase())
                })
            } else {
                throw new Error('unexpected return')
            }
        })
        .catch(error => {
            console.error('fetchOptionCoinDelta error: ', error)
        })
    }
}

export function fetchFundingRateHistory (symbols=[], from) {
    return (dispatch) => new Promise((resovle, reject) => {
        const queryString = getQueryString({
            symbols: symbols.join(),
            from
        }) 
        dispatch(secureFetch(`${ELF_API_BASE_URL}/funding-rate-history?${queryString}`))
        .then(response => {
            if (response.status === 200) {
                return response.json()
            } else {
                reject({ error: `Response status: ${response.status}` })
            }
        })
        .then(body => {
            dispatch({
                type: UPDATE_FUNDING_RATE_HISTORY,
                fundingRateHistory: body
            })
            resovle(body)
        })
        .catch(error => {
            console.error('fetchFundingRateHistory error: ', error)
        })
    })
}

export function fetchFundingRateHistorySymbols () {
    return (dispatch) => new Promise((resolve) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/funding-rate-history/symbols`))
        .then(response => {
            if (response.status === 200) {
                return response.json()
            } else {
                throw new Error('unexpected status code')
            }
        })
        .then(body => {
            dispatch({
                type: UPDATE_FUNDING_RATE_HISTORY_SYMBOLS,
                symbols: body
            })
            resolve(body)
        })
        .catch(error => {
            console.error('fetchFundingRateHistorySymbols error: ', error)
        })
    }) 
}

export function fetchDefiLendingInfo () {
    return (dispatch) => new Promise(resolve => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/defi-lending-info/latest`))
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(body => {
            dispatch({
                type: UPDATE_DEFI_LENDING_INFO,
                info: body
            })
            resolve(body)
        })
        .catch(error => {
            console.error('fetchDefiLendingInfo error', error)
        })
    })
}

export function updateSymbolOrderBooks (symbolOrderBooks) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SYMBOL_ORDER_BOOKS,
            symbolOrderBooks
        })
    }
}

export function removeSymbolOrderBook (symbolName) {
    return (dispatch) => {
        dispatch({
            type: REMOVE_SYMBOL_ORDER_BOOK,
            symbolName
        })
    }
}

export function fetchFundingTags () {
    return (dispatch) => new Promise(resolve => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/funding-tags`))
        .then(response => response.json())
        .then(body => {
            if (body && _.isArray(body.items)) {
                dispatch(updateFundingTags(body.items))
                resolve(body.items)
            }
        })
        .catch(error => {
            console.error('fetchFundingTags error: ', error)
        })
    })
}

export function updateFundingTags (fundingTags=[]) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_FUNDING_TAGS,
            fundingTags
        })
    }
}

export function uploadFundingTags (newFundingTags) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/funding-tags`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newFundingTags)
        }))
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}

export function fetchOptionSymbolAdditionalInfo () {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/optionSymbolAdditionalInfo`))
        .then(response => response.json())
        .then(body => {
            const optionSymbolAdditionalInfo = _.keyBy(body, 'symbol')
            dispatch({
                type: UPDATE_OPTION_SYMBOL_ADDITIONAL_INFO,
                optionSymbolAdditionalInfo
            })
            resolve(optionSymbolAdditionalInfo)
        })
        .catch((error) => {
            console.error(`fetchOptionSymbolAdditionalInfo error: `, error)
            reject(error)
        })
    })
}

export function enableSymbol (symbol='') {
    return async (dispatch) => {
        try {
            const response = await dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/symbol/enable`, {
                method: 'POST',
                body: JSON.stringify({ symbol })
            }))
            return response
        } catch (error) {
            console.error('Enable Symbol error: ', error)
            throw error
        }
    }
}

export function syncSymbol (symbol='') {
    return async (dispatch) => {
        try {
            const response = await dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/symbol/sync`, {
                method: 'POST',
                body: JSON.stringify({ symbol })
            }))
            let symbolItem = null, errorMessage = ''
            if (response.status === 200) {
                const body = await response.json()
                symbolItem = _.get(body, 'info.0')
                if (!_.isEmpty(symbolItem)) {
                    dispatch(updateSymbolItem(symbolItem))
                }
            } else {
                errorMessage = await response.text()
            }
            return {
                status: response.status,
                symbolItem,
                errorMessage
            }
        } catch (error) {
            console.error('Sync Symbol error: ', error)
            throw error
        }
    }
}

export async function addSymbol (symbolName='') {
    return async (dispatch) => {
        try {
            const response = await dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/symbol`, {
                method: 'POST',
                body: JSON.stringify({ symbol: symbolName })
            }))
            return response
        } catch (error) {
            console.error('Add Symbol error: ', error)
            throw error
        }
    }
}

export function updateSymbol (symbolItem={}) {
    return async (dispatch) => {
        try {
            const response = await dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/symbol/parameter`, {
                method: 'POST',
                body: JSON.stringify({
                    symbol: symbolItem?.symbol_name,
                    ..._.pick(symbolItem, ['multiplier', 'price_tick', 'tick_size', 'trading_in_notional'])
                })
            }))
            return response
        } catch (error) {
            console.error('Update Symbol error: ', error)
            throw error
        }
    }
}

export function fetchSymbolParameter (symbolName='') {
    return async (dispatch) => {
        try {
            const response = await dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/symbol/parameter?symbol=${encodeURIComponent(symbolName)}`))
            const body = await response.json()
            const symbolItem = _.get(body, 'info.0')
            if (!_.isEmpty(symbolItem)) {
                dispatch(updateSymbolItem(symbolItem))
            }
            return symbolItem
        } catch (error) {
            console.error('fetchSymbolParameter error: ', error)
            throw error
        }
    }
}

export function syncOptionSymbols (exchange='') {
    return async (dispatch) => {
        try {
            const response = await dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/symbol/sync_option_symbols`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ exchange })
            }))
            return response
        } catch (error) {
            console.error('Sync Option Symbols error: ', error)
            throw error
        }
    }
}

export function fetchSymbolLeverage ({ accountName, symbol }) {
    return async (dispatch) => {
        try {
            const queryString = getQueryString({
                account_name: accountName,
                symbol
            }) 
            const response = await dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/leverage?${queryString}`))
            return response
        } catch (error) {
            console.error('fetchSymbolLeverage error: ', error)
            throw error
        }
    }
}

export function updateSymbolLeverage ({ accountName, symbol, leverage, comment }) {
    return async (dispatch) => {
        try {
            const response = await dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/leverage`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    account_name: accountName,
                    symbol,
                    leverage: _.toString(leverage),
                    comment: comment ?? ''
                })
            }))
            return response
        } catch (error) {
            console.error('updateSymbolLeverage error: ', error)
            throw error
        }
    }
}

export function updateBybitRiskId ({ accountName, symbol, riskId, comment }) {
    return async (dispatch) => {
        try {
            const response = await dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/bybit-risklimit`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    account_name: accountName,
                    symbol,
                    risk_id: _.toInteger(riskId),
                    comment: comment ?? ''
                })
            }))
            return response
        } catch (error) {
            console.error('updateBybitRiskId error: ', error)
            throw error
        }
    }
}

export function fetchSymbolPriceMonitors () {
    return async (dispatch) => {
        try {
            const response = await dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/symbol_price_monitor_config`))
            const body = await response.json()
            if (_.isArray(body?.info)) {
                dispatch({
                    type: UPDATE_SYMBOL_PRICE_ALERT_MONITORS,
                    priceAlertMonitors: body?.info
                })
            }
            return body
        } catch (error) {
            console.error('fetchSymbolLeverage error: ', error)
            throw error
        }
    }
}

export function postSymbolPriceMonitor ({ symbol, alert_type, low_threshold, high_threshold }) {
    return async (dispatch) => {
        try {
            const response = await dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/symbol_price_monitor_config`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    symbol,
                    alert_type,
                    low_threshold: Number(low_threshold),
                    high_threshold: Number(high_threshold)
                })
            }))
            const body = await response.json()
            if (_.isArray(body?.info)) {
                dispatch({
                    type: UPDATE_SYMBOL_PRICE_ALERT_MONITORS,
                    priceAlertMonitors: body?.info
                })
            }
            return response
        } catch (error) {
            console.error('postSymbolPriceMonitor error: ', error)
            throw error
        }
    }
}

export function deleteSymbolPriceMonitor ({ symbol, alert_type }) {
    return async (dispatch) => {
        try {
            const response = await dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/symbol_price_monitor_config`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    symbol,
                    alert_type
                })
            }))
            const body = await response.json()
            if (_.isArray(body?.info)) {
                dispatch({
                    type: UPDATE_SYMBOL_PRICE_ALERT_MONITORS,
                    priceAlertMonitors: body?.info
                })
            }
            return response
        } catch (error) {
            console.error('deleteSymbolPriceMonitor error: ', error)
            throw error
        }
    }
}